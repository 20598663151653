<script>
import {
  layoutComputed
} from "@/state/helpers";
import Vertical from "./vertical";

export default {
  components: {
    Vertical,
  },
  computed: {
    ...layoutComputed,
  },
  mounted() {
    document.body.setAttribute("data-sidebar", "dark")
  }
};
</script>

<template>
  <div>
    <Vertical v-if="layoutType === 'vertical'" :layout="layoutType" key="vertidldl">
      <slot/>
    </Vertical>
  </div>
</template>
