import {api} from "@/service";

export const state = {}

export const actions = {
  // eslint-disable-next-line no-empty-pattern
  addBanner({}, formData) {
    return api.postMultiPart('/banner/add', formData)
  },
  // eslint-disable-next-line no-empty-pattern
  removeBannerById({}, idBanner) {
    return api.delete(`/banner/remove/${idBanner}`).then(
      () => {
      }
    )
  }
}