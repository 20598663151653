<template>
  <ul v-if="pager.pages && pager.pages.length" class="pagination pagination-rounded justify-content-center mb-2">
    <!-- Seta pra esquerda -->
    <li class="page-item" :class="{'disabled' : pager.currentPage === 1}" @click="setPage(1)">
      <a class="page-link" href="javascript: void(0);" aria-label="Previous">
        <i class="mdi mdi-chevron-left"></i>
      </a>
    </li>

    <!-- Numero das paginas -->
    <li v-for="page in pager.pages" :key="page" class="page-item" :class="{'active': pager.currentPage === page}">
      <a class="page-link" @click="setPage(page)">{{ page }}</a>
    </li>

    <!-- Seta para a direita -->
    <li class="page-item" :class="{'disabled' : pager.currentPage === pager.totalPages}"
        @click="setPage(pager.totalPages)">
      <a class="page-link" href="javascript: void(0);" aria-label="Next">
        <i class="mdi mdi-chevron-right"></i>
      </a>
    </li>
  </ul>
</template>

<script>
import paginate from 'jw-paginate';

export default {
  name: "JMPagination",
  emits: ['changePage'],
  props: {
    items: {
      type: Array,
      required: true
    },
    initialPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    maxPages: {
      type: Number,
      default: 8
    },
  },
  data() {
    return {
      pager: {}
    }
  },
  created() {
    // this.setPage(this.initialPage)
  },
  methods: {
    setPage(page) {
      const {items, pageSize, maxPages} = this
      const pager = paginate(items.length, page, pageSize, maxPages);
      const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);
      this.pager = pager;
      this.$emit('changePage', pageOfItems);
    }
  },
  watch: {
    items() {
      this.setPage(this.initialPage)
    }
  }
}
</script>

<style>
.page-item {
  cursor: pointer;
}
</style>
