import { api } from '@/service';

export const state = {
  tissues: [],
  tissueColors: [],
  newTissueColor: null,
  newTissueProduct: null,
};

export const mutations = {
  UPDATE_TISSUES: (state, value) => (state.tissues = value),
  UPDATE_TISSUE_COLORS: (state, value) => (state.tissueColors = value),
  UPDATE_NEW_TISSUE_COLOR: (state, value) => (state.newTissueColor = value),
  UPDATE_NEW_TISSUE_PRODUCT: (state, value) => (state.newTissueProduct = value),
};

export const actions = {
  /**
   * Busca todos os tecidos
   * @param {*} param0
   * @returns
   */
  getTissues({ commit }) {
    return api.get('/tissue').then((response) => {
      if (response.status === 200) {
        commit('UPDATE_TISSUES', response.data);
      }
    });
  },
  /**
   * Retorna todas cores
   * @param {*} param0
   * @returns
   */
  getTissueColors({ commit }) {
    return api.get('/tissue/colors').then((response) => {
      if (response.status === 200) {
        commit('UPDATE_TISSUE_COLORS', response.data);
      }
    });
  },
  addColorTissue({ commit }, payload) {
    return api.post('/tissue/color/add', payload).then((response) => {
      if (response.status === 201) {
        commit('UPDATE_NEW_TISSUE_COLOR', response.data);
      }
    });
  },
  // eslint-disable-next-line no-empty-pattern
  addColorImageTissue({}, payload) {
    return api.post('/tissue/add/image/product', payload);
  },
  // eslint-disable-next-line no-empty-pattern
  removeColorImageTissue({}, id) {
    return api.delete(`/tissue/delete/image/product/${id}`);
  },
  addTissueForProduct({ commit }, payload) {
    console.log(payload);
    return api.post('/tissue/product-tissue/add', payload).then((response) => {
      if (response.status === 201) {
        commit('UPDATE_NEW_TISSUE_PRODUCT', response.data);
      }
    });
  },
  // eslint-disable-next-line no-empty-pattern
  deleteTissueProduct({}, id) {
    return api.delete(`/tissue/product-tissue/${id}`);
  },
  // eslint-disable-next-line no-empty-pattern
  deleteTissueProductColor({}, id) {
    return api.delete(`/tissue/product-tissue/color/${id}`);
  },
};
