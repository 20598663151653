import store from "@/state/store";
import Default from "@/router/views/dashboards/default";
import Login from "@/router/views/account/login";
import Logout from "@/router/views/account/logout";
import Products from "@/router/views/products/products";
import AddProduct from "@/router/views/products/add-product";
import EditProduct from "@/router/views/products/edit-product";
import Discounts from "@/router/views/discount/discounts";
import AddDiscount from "@/router/views/discount/add-discount";
import EditDiscount from "@/router/views/discount/edit-discount";
import Gallery from "@/router/views/gallery/gallery";
import Category from "@/router/views/category/category";

export default [
  {
    path: "/",
    name: "default",
    meta: {
      authRequired: true,
    },
    // component: () => import("./views/dashboards/default"),
    component: Default,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({name: "default"});
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? {name: "default"} : {...routeFrom}
        );
      },
    },
  },

  /**
   * PRODUCTS
   */
  {
    path: "/products",
    name: "Products",
    meta: {authRequired: true},
    component: Products,
  },
  {
    path: "/products/add",
    name: "Add Product",
    meta: {authRequired: true},
    component: AddProduct,
  },
  {
    path: "/products/edit/:id/:slogan?",
    name: "Edit Product",
    meta: {authRequired: true},
    component: EditProduct,
  },
  {
    path: "/products/edit",
    meta: {authRequired: true},
    redirect: () => {
      return {path: '/products'}
    },
  },
  /**
   * DISCOUNT
   */
  {
    path: "/discounts",
    name: "Discounts",
    meta: {authRequired: true},
    component: Discounts,
  },
  {
    path: "/discount/add",
    name: "Add Discount",
    meta: {authRequired: true},
    component: AddDiscount,
  },
  {
    path: "/discount/edit/:id/",
    name: "Edit Discount",
    meta: {authRequired: true},
    component: EditDiscount
  },
  {
    path: "/discount/edit",
    meta: {authRequired: true},
    redirect: () => {
      return {path: '/discounts'}
    },
  },

  /**
   * Galeria
   */

  {
    path: "/gallery",
    name: "Gallery",
    meta: {authRequired: true},
    component: Gallery
  },

  /* Paginas de erros */
  {
    path: "/:pathMatch(.*)*",
    name: "Error-404",
    meta: {authRequired: false},
    component: () => import("./views/utility/404"),
  },

  /**
   * Categorias
   */
  {
    path: '/category',
    name: 'Category',
    meta: {authRequired: true},
    component: Category
  }

];