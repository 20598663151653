import {api} from "@/service";

export const state = {
  currentUser: null,
  errorLogin: null
}

export const mutations = {
  // Atualiza o acessToken do user
  SET_CURRENT_USER(state, value) {
    state.currentUser = value
  },
  // Deu erro no login
  SET_ERROR_LOGIN(state, value) {
    state.errorLogin = value
  }
}

export const actions = {

  /**
   * Verifica o email e senha
   * @param commit
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  login({commit}, payload) {
    return api.signIn('/auth/signin', payload).then(
      (response) => {
        // Usuario ou senha incorretos
        if (response.status === 401) commit('SET_ERROR_LOGIN', true)
        else if (response.status === 201) {
          const {token} = response.data
          commit('SET_CURRENT_USER', token)
          commit('SET_ERROR_LOGIN', false)
          // Salva a sessão
          saveState('access-token', token)
        }
      }
    )
  },
  logout({commit}) {
    commit('SET_CURRENT_USER', null)
    // saveState('access-token', null)
    window.localStorage.removeItem('access-token')
  }
}

/**
 *
 * @param key
 * @param state
 */
function saveState(key, state) {
  window.localStorage.setItem(key, state)
}