/**
 * Faz a paginação de uma lista
 * @param array
 * @param pageSize
 * @param pageNumber
 */

export function paginate(array, pageSize, pageNumber) {
  if (pageSize === 0 || !pageSize) pageSize = 5;
  if (pageNumber === 0 || !pageNumber) pageNumber = 1;

  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
}

/**
 * Filtra os produtos pela a string,
 * Verifica se o nome do produto ou nome da categoria tem a searchString.
 */
export function filterProducts(searchString, products) {
  return searchString
    ? products.filter((obj) => {
        // console.log(obj.nome.toUpperCase().includes(searchString.toUpperCase()))
        try {
          if (
            obj.nome.toUpperCase().includes(searchString.toUpperCase()) ||
            obj.categoria.nome
              .toUpperCase()
              .includes(searchString.toUpperCase()) ||
            obj.descricao.toUpperCase().includes(searchString.toUpperCase())
          )
            return true;
        } catch (e) {
          return false;
        }
      })
    : products;
}

/**
 * Filtra as categorias pelo o nome
 * @param {*} searchString
 * @param {*} categories
 * @returns
 */
export function filterCategory(searchString, categories) {
  return searchString
    ? categories.filter((obj) => {
        try {
          if (obj.nome.toUpperCase().includes(searchString.toUpperCase()))
            return true;
        } catch (_) {
          return false;
        }
      })
    : categories;
}

/**
 * Converte uma imagem no tipo File
 * @param url
 * @param filename
 * @param mimeType
 * @returns {Promise<File>}
 */
export function convertImgToFile(url, filename, mimeType) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

// export function sortArray(a, b) {
//   if (a.produto_galeria.length < b.produto_galeria.length) return 1;
//   if (a.produto_galeria.length > b.produto_galeria.length) return -1;
//   return 0;
// }
