import { api } from '@/service';

export const state = {
  insertImagesGallery: [],
  openModalGallery: false,
  galleryImages: [],
  insertImagesGalleryTissue: null,
};

export const mutations = {
  ADD_INSERT_IMAGE_GALLERY: (state, value) =>
    state.insertImagesGallery.push(value),
  CLEAR_INSERT_IMAGE_GALLERY: (state, value) =>
    (state.insertImagesGallery = value),
  UPDATE_GALLERY_IMAGES: (state, value) => (state.galleryImages = value),
  UPDATE_INSERT_IMAGE_GALLERY_TISSUE: (state, value) =>
    (state.insertImagesGalleryTissue = value),
};

export const getters = {
  getInserted: (state) => {
    return state.insertImagesGallery;
  },
  getInsertedTissueGallery: (state) => {
    return state.insertImagesGalleryTissue;
  },
};

export const actions = {
  /**
   * Adiciona as imagens na galeria
   * @param commit
   * @param formData
   */
  insertImageGallery({ commit }, formData) {
    return api.postMultiPart('/galeria/add', formData).then((response) => {
      if (response.status === 201) {
        commit('ADD_INSERT_IMAGE_GALLERY', response.data);
      }
    });
  },
  /**
   * Adiciona as imagens na galeria do tecido
   * @param commit
   * @param formData
   */
  insertImageGalleryTissue({ commit }, formData) {
    return api.postMultiPart('/galeria/add', formData).then((response) => {
      if (response.status === 201) {
        commit('UPDATE_INSERT_IMAGE_GALLERY_TISSUE', response.data);
      }
    });
  },
  clearInsertImageGallery: ({ commit }) =>
    commit('CLEAR_INSERT_IMAGE_GALLERY', []),
  /**
   * Busca todas as imagens da galeria
   * @returns {Promise<AxiosResponse<any>>}
   */
  getAllImagesGallery({ commit }) {
    return api.get('/galeria/all').then((response) => {
      if (response.status === 200)
        commit('UPDATE_GALLERY_IMAGES', response.data);
    });
  },
  removeImageFromGallery({ dispatch }, idGallery) {
    return api.delete(`/galeria/remove/${idGallery}`).then((response) => {
      if (response.status === 200) dispatch('getAllImagesGallery');
    });
  },
};
