<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import useVuelidate from '@vuelidate/core';
import { ref } from 'vue';
import {
  categoryMethods,
  galleryMethods,
  productsMethods,
  tissueMethods,
} from '@/state/helpers';
import { mapGetters, mapState } from 'vuex';
import { baseURL } from '@/service';
import GalleryModal from '@/components/GalleryModal';
import DropZone from '@/components/widgets/dropZone';
import Swal from 'sweetalert2';
import { helpers, required } from '@vuelidate/validators';
import { api } from '@/service';

export default {
  page: {
    title: 'Editar Produto',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    DropZone,
    GalleryModal,
    Layout,
    PageHeader /* Dicas */ /* DropZone */,
  },
  /**
   * Configura o dropzone
   * @returns {{drop: drop, selectedFile: selectedFile, dropzoneFile: Ref<UnwrapRef<*[]>>, v$: Ref<Validation> | Ref<Validation<ValidationArgs, {[key in keyof ValidationArgs]: any}>> | ComputedRef<any>}}
   */
  setup() {
    let dropzoneFile = ref([]);
    // let imagesThumbail = reactive({})
    const drop = (e) => {
      dropzoneFile.value.push(e.dataTransfer.files[0]);
    };
    const selectedFile = () => {
      const f = document.querySelector('.dropzoneFile').files[0];
      dropzoneFile.value.push(f);
      // imagesThumbail[f.name + '_' + f.size] = {thumbnail: false}
    };
    return { dropzoneFile, drop, selectedFile, v$: useVuelidate() };
  },
  computed: {
    ...mapState('category', ['category']),
    ...mapState('product', ['findProduct']),
    ...mapGetters('gallery', ['getInserted', 'getInsertedTissueGallery']),
    ...mapState('tissue', [
      'tissues',
      'tissueColors',
      'newTissueColor',
      'newTissueProduct',
    ]),
  },
  data() {
    return {
      title: 'Editar Produto',
      items: [
        {
          text: 'Loja',
          href: '/',
        },
        {
          text: 'Produtos',
          href: '/products',
        },
        {
          text: 'Editar Produto',
          active: true,
        },
      ],
      product: null,
      showModal: false,
      openModalGallery: false,
      baseURL,
      imagesFromGallery: [],
      newCategory: null,
      newStatus: 'Desativado',
      vCategory: {
        name: '',
      },
      categoryChecked: true,
      submittedCategory: true,
      imagesThumbnail: [],

      showModalTissue: false,
      openModalGalleryTecido: false,
      openModalGalleryTecidoChoose: {
        tecido: '',
        cor: '',
      },
      tissueModal: {
        select: '',
        colors: [],
      },
    };
  },
  async created() {
    await this.getCategory();
    await this.getTissues();
    await this.getTissueColors();
    await this.getProduct();
  },
  methods: {
    ...categoryMethods,
    ...productsMethods,
    ...galleryMethods,
    ...tissueMethods,
    async getProduct() {
      const idProduct = this.$route['params']['id'];
      if (idProduct) {
        if (this.$route['params']['product']) {
          this.product = JSON.parse(this.$route['params']['product']);
        } else {
          await this.findById(Number(idProduct));
          this.product = { ...this.findProduct };
        }
        this.newCategory = this.product['categoria']['nome'];
        this.newStatus = this.product['status'] ? 'Ativo' : 'Desativado';
      }
    },
    async editProduct() {
      /**
       * Antes de atualizar o produto vamos registrar as imagens na galeria
       */
      if (this.dropzoneFile.length > 0)
        await this.insertImageG(this.dropzoneFile);

      /**
       * Atualiza as informações do produto
       */
      await this.updateProductById({
        id: this.product['id'],
        product: {
          nome: this.product['nome'],
          descricao: this.product['descricao'],
          valor: Number(String(this.product['valor']).replace('R$ ', '')),
          categoria_id: this.category.filter((c) => {
            if (c['nome'] === this.newCategory) return true;
          })[0]['id'],
          status: this.newStatus === 'Ativo',
        },
      });

      /**
       * Registra o vínculo com as imagens
       */
      if (this.dropzoneFile.length > 0 || this.imagesFromGallery.length > 0) {
        const ids = [
          // Preenche com os ids das imagens novas da galeria
          ...this.getInserted.map((c) => {
            return c['id'];
          }),
          // Preenche com os ids das imagens selecionadas da galeria
          ...this.imagesFromGallery.map((c) => {
            return c['id'];
          }),
        ];
        console.log(ids);
        await this.addImageToProductUpdate({ id: this.product['id'], ids });
      }

      await this.clearInsertImageGallery();

      Swal.fire(
        'Produto atualizado',
        'Seu produto foi atualizado com sucesso!',
        'success',
      );
    },
    /**
     * Adiciona as imagens a galeria
     * @param payload
     * @returns {Promise<void>}
     */
    async insertImageG(payload) {
      for (const file of payload) {
        let formData = new FormData();
        formData.append('file', file);
        await this.insertImageGallery(formData);
      }
    },
    /**
     * Remove a imagem do produto
     * @param image
     * @returns {Promise<void>}
     */
    async removeImage(image) {
      await this.removeImageToProduct(image['id']);

      await this.findById(Number(this.product['id']));
      this.product = { ...this.findProduct };

      Swal.fire(
        'Produto atualizado',
        'Imagem removida com sucesso!',
        'success',
      );
    },
    /**
     * Registra na memória a imagem seleciona
     * @param image
     */
    selectImageGallery(image) {
      this.imagesFromGallery.push(image);
      this.openModalGallery = false;
    },
    removeImageFromGallery(image) {
      const tmp = [];

      this.imagesFromGallery.forEach((i) => {
        if (i !== image) tmp.push(i);
      });

      this.imagesFromGallery = tmp;
    },
    /**
     * Gera uma url apartir do file
     * @param file
     * @returns {string}
     */
    getUrl(file) {
      return URL.createObjectURL(file);
    },
    /**
     * Remove uma imagem da lista
     * @param file
     */
    removeFile(file) {
      const tmp = [];
      this.dropzoneFile.forEach((f) => {
        if (f !== file) tmp.push(f);
      });
      this.dropzoneFile = tmp;
    },
    /**
     * Cria uma nova categoria
     * @returns {Promise<void>}
     */
    async createCategory() {
      this.submittedCategory = true;
      this.v$.$touch();

      /**
       * Verifica se o input da categoria não foi preenchido
       */
      if (
        this.v$.$errors.filter((e) => {
          if (e['$propertyPath'] === 'vCategory.name') return true;
        }).length > 0
      ) {
        return;
      } else {
        await this.addCategory({
          nome: this.vCategory.name,
          status: this.categoryChecked,
        });

        // TODO: Implementar a captura de erros
        this.showModal = false;
        this.submittedCategory = false;
      }
    },
    async changeThumbnail(product, image) {
      const data = {
        produto_imagem: image['id'],
        data: {
          thumbnail: !image['thumbnail'],
          produto_id: product['id'],
        },
      };
      await this.changeThumbnailId(data);

      this.$router.go();
    },

    /**
     * Recebe a imagem do computador e faz upload direto para a galeria
     */
    async uploadFile(tecido, tecidoCorId) {
      console.log(tecido, tecidoCorId);
      const imageFile = this.$refs[`${tecido}_${tecidoCorId}`][0].files[0];
      let formData = new FormData();
      formData.append('file', imageFile);

      // Salva a imagem na galeria
      await this.insertImageGalleryTissue(formData);

      // Adiciona a imagem no tecido
      await this.addColorImageTissue({
        galeria_imagem_id: this.getInsertedTissueGallery['id'],
        tecido_cor_id: tecidoCorId,
      });

      this.$router.go(0);
    },
    /**
     * Insere uma imagem da galeria
     */
    async selectImageGalleryTecido(data) {
      const { image, cor } = data;
      // Adiciona a imagem no tecido
      await this.addColorImageTissue({
        galeria_imagem_id: image['id'],
        tecido_cor_id: cor,
      });

      this.$router.go(0);
    },
    openModalGalleryTecidoClick(tecido, tecidoCorId) {
      this.openModalGalleryTecidoChoose.tecido = tecido;
      this.openModalGalleryTecidoChoose.cor = tecidoCorId;
      this.openModalGalleryTecido = true;
    },
    /**
     * Remove a imagem do tecido
     */
    async removeImageTissue(id) {
      await this.removeColorImageTissue(id);
      this.$router.go(0);
    },
    addTissueColor() {
      this.tissueModal.colors.push({ name: '' });
    },
    deleteTissueColor(index) {
      this.tissueModal.colors.splice(index, 1);
    },
    async saveNewTissue() {
      if (this.tissueModal['colors']) {
        const tecidoId = this.getTissueId(this.tissueModal['select']);
        await this.addTissueForProduct({
          produto_id: this.product['id'],
          tecido_id: tecidoId,
        });

        let inserts = [];
        await this.tissueModal['colors'].forEach(async (color) => {
          inserts.push(
            this.addImageToTissue(
              this.getColorId(color['name']),
              this.newTissueProduct['idproduto_tecido'],
            ),
          );
        });

        Promise.all(inserts).then((res) => {
          console.log(res);
          this.showModalTissue = false;
          this.$router.go(0);
        });
      }
    },
    addImageToTissue(cor_id, idproduto_tecido) {
      return new Promise((res, rej) => {
        api
          .post('/tissue/color/add', {
            cor_id,
            idproduto_tecido,
          })
          .then(
            (response) => {
              res(response);
            },
            (err) => {
              rej(err);
            },
          );
      });
    },
    getTissueId(tecido) {
      let id;
      this.tissues.forEach((tt) => {
        if (tecido === tt['nome']) id = tt['id'];
      });
      return id;
    },
    getColorId(name) {
      let id;
      this.tissueColors.forEach((color) => {
        if (color['nome'] === name) id = color['id'];
      });

      return id;
    },
    async removeTissue(idTissueProduct) {
      await this.deleteTissueProduct(idTissueProduct);
      this.$router.go(0);
    },
    async removeTissueColor(tecidoCor) {
      await this.deleteTissueProductColor(tecidoCor['id']);
      this.$router.go(0);
    },
  },
  validations: {
    product: {
      name: {
        required: helpers.withMessage('Nome do produto obrigatório', required),
      },
      price: {
        required: helpers.withMessage(
          'Valor do produto é obrigatório',
          required,
        ),
      },
      category: {
        required: helpers.withMessage(
          'Você precisa escolher uma categoria',
          required,
        ),
      },
      description: {
        required: helpers.withMessage(
          'Preencha uma descrição para o produto',
          required,
        ),
      },
    },
    vCategory: {
      name: {
        required: helpers.withMessage(
          'Você precisa escolher uma categoria',
          required,
        ),
      },
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="product">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Edite as informações do produto</h4>
            <p class="card-title-desc">
              Insira corretamente os dados para editar o produto
            </p>

            <!-- Formulario -->
            <form @submit.prevent="editProduct">
              <div class="row">
                <!-- Coluna esquerda -->
                <div class="col-sm-6">
                  <!-- Nome do produto -->
                  <div class="mb-3">
                    <label for="productname">Nome</label>
                    <input
                      id="productname"
                      v-model="product['nome']"
                      name="name"
                      type="text"
                      class="form-control"
                      :maxlength="50"
                    />
                    <!-- Max Length -->
                    <div v-if="product['nome']" class="text-center">
                      <p
                        class="badge position-absolute"
                        :class="{
                          'bg-success': product['nome'].length !== 50,
                          'bg-danger': product['nome'].length === 50,
                        }"
                      >
                        {{ product['nome'].length }} / 50
                      </p>
                    </div>
                  </div>

                  <!-- Preço do produto -->
                  <div class="mb-3">
                    <label for="price">Preço</label>
                    <input
                      id="price"
                      name="price"
                      v-model="product['valor']"
                      v-maska="[
                        'R$ #.##',
                        'R$ ##.##',
                        'R$ ###.##',
                        'R$ ####.##',
                        'R$ ##',
                        'R$ ###',
                      ]"
                      type="text"
                      class="form-control"
                    />
                  </div>

                  <!-- Categoria do produto -->
                  <div class="mb-3">
                    <label class="control-label">Categoria</label>
                    <div
                      class="d-flex justify-content-between align-content-center gap-1"
                    >
                      <div style="width: 100%">
                        <b-form-select
                          class="form-select"
                          id="formrow-inputState"
                          :options="
                            category.map((c) => {
                              return c['nome'];
                            })
                          "
                          v-model="newCategory"
                          type="text"
                        ></b-form-select>
                      </div>
                      <b-button variant="primary" @click="showModal = true">
                        Criar
                      </b-button>
                    </div>
                  </div>

                  <!-- Status do produto -->
                  <div class="mb-3">
                    <label for="price">Status</label>
                    <b-form-select
                      :options="['Ativo', 'Desativado']"
                      v-model="newStatus"
                    ></b-form-select>
                  </div>
                </div>
                <!-- Coluna direita -->
                <div class="col-sm-6">
                  <!-- Descrição do produto -->
                  <div class="mb-3">
                    <label for="productdesc">Descrição do produto</label>
                    <textarea
                      id="productdesc"
                      class="form-control"
                      rows="6"
                      v-model="product['descricao']"
                    ></textarea>
                  </div>

                  <!-- Upload das imagens  -->
                  <div
                    class="d-flex justify-content-between upload align-items-center"
                  >
                    <label>Inserir imagem</label>
                    <button
                      class="btn btn-primary mb-1"
                      @click.prevent="openModalGallery = true"
                    >
                      Abrir da galeria
                      {{
                        imagesFromGallery.length > 0
                          ? imagesFromGallery.length
                          : ''
                      }}
                    </button>
                  </div>

                  <DropZone
                    label-text="Selecionar do Computador"
                    @drop.prevent="drop"
                    @change="selectedFile"
                  />
                </div>
              </div>

              <!-- Imagens do produto -->
              <div class="row">
                <p class="text-center py-4">
                  <strong
                    >Atenção! A proporção ideal para as imagens é: 300px300px,
                    400px400px, 500px500px etc</strong
                  >
                </p>

                <template v-if="product['produto_galeria'].length >= 1">
                  <div
                    class="col col-md-3"
                    v-for="img in product['produto_galeria']"
                    :key="img['id']"
                  >
                    <div class="body-card">
                      <img
                        style="width: 100%; height: 100%"
                        class="rounded me-2"
                        alt="200x200"
                        width=""
                        :src="
                          baseURL +
                          `/public/gallery/${img['galeria_imagem']['filename']}`
                        "
                        data-holder-rendered="true"
                      />
                      <div
                        class="d-flex mt-2 justify-content-center align-items-center gap-1"
                      >
                        <label for="" style="margin-bottom: 0"
                          >Definir como principal</label
                        >
                        <input
                          type="checkbox"
                          @click="changeThumbnail(product, img)"
                          :checked="img['thumbnail']"
                          :disabled="img['thumbnail']"
                        />
                      </div>
                    </div>
                    <div
                      class="remover-img text-center"
                      @click.prevent="removeImage(img)"
                    >
                      <a href="">Remover imagem</a>
                    </div>
                  </div>
                </template>
                <div v-else>
                  <h4
                    class="text-center text-danger"
                    v-if="
                      imagesFromGallery.length === 0 &&
                      dropzoneFile.length === 0
                    "
                  >
                    Esse produto não possui nenhuma imagem
                  </h4>
                </div>

                <!-- Mostra as imagens selecionas para vinculo com o produto -->

                <!-- Imagens do computador -->
                <div
                  class="col col-md-3"
                  v-for="file in dropzoneFile"
                  :key="file.name"
                >
                  <div class="body-card">
                    <img
                      style="width: 100%; height: 100%"
                      class="rounded me-2"
                      :alt="file.name"
                      width=""
                      :src="getUrl(file)"
                      data-holder-rendered="true"
                    />
                  </div>
                  <div
                    class="remover-img text-center"
                    @click.prevent="removeFile(file)"
                  >
                    <a href="">Remover imagem</a>
                  </div>
                </div>

                <!-- Imagens da galeria -->
                <div
                  class="col col-md-3"
                  v-for="img in imagesFromGallery"
                  :key="img['id']"
                >
                  <div class="body-card">
                    <img
                      style="width: 100%; height: 100%"
                      class="rounded me-2"
                      alt="200x200"
                      width=""
                      :src="`${baseURL}/public/gallery/${img['filename']}`"
                      data-holder-rendered="true"
                    />
                  </div>
                  <div
                    class="remover-img text-center"
                    @click.prevent="removeImageFromGallery(img)"
                  >
                    <a href="">Remover imagem</a>
                  </div>
                </div>
              </div>

              <!-- Botoẽs -->
              <div class="mt-2 text-center">
                <button type="submit" class="btn btn-primary me-1">
                  Salvar
                </button>
                <button
                  class="btn btn-secondary"
                  @click.prevent="this.$router.push({ name: 'Products' })"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Tecido -->

    <div class="row" v-if="product">
      <div class="col-12" v-if="product['tecido']">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Informações do tecido</h4>
            <p class="card-title-desc">Edite as informações do tecido</p>

            <div class="row">
              <div class="col">
                <button
                  class="btn btn-primary mb-3"
                  @click.prevent="showModalTissue = true"
                >
                  Adicionar novo tecido
                </button>
              </div>
              <div class="col">
                <ul
                  v-for="tecido in product['produto_tecido']"
                  :key="tecido['idproduto_tecido']"
                >
                  <li>
                    {{ tecido['tecido']['nome'] }} -
                    <a
                      href=""
                      @click.prevent="removeTissue(tecido['idproduto_tecido'])"
                      >Remover</a
                    >
                  </li>
                  <ul
                    v-for="tecidoCor in tecido['tecido_cor']"
                    :key="tecidoCor['id']"
                  >
                    <li>
                      {{ tecidoCor['cores']['nome'] }} -
                      <a href="" @click.prevent="removeTissueColor(tecidoCor)"
                        >Remover</a
                      >
                    </li>
                  </ul>
                </ul>
              </div>
            </div>

            <form action="">
              <div class="row">
                <b-tabs
                  justified
                  nav-class="nav-tabs-custom"
                  content-class="p-3 text-muted"
                >
                  <b-tab
                    v-for="tecido in product['produto_tecido']"
                    :key="tecido['idproduto_tecido']"
                  >
                    <template v-slot:title>
                      <span>{{ tecido['tecido']['nome'] }}</span>
                    </template>

                    <b-tabs
                      pills
                      vertical
                      nav-class="p-0"
                      content-class="pt-0 px-2 text-muted width100"
                    >
                      <b-tab
                        v-for="tecidoCor in tecido['tecido_cor']"
                        :title="tecidoCor['cores']['nome']"
                        :key="tecidoCor['id']"
                        title-item-class="mb-2"
                        style="border-color: blue"
                      >
                        <b-card-text>
                          <div class="row">
                            <div class="col">
                              <div class="d-flex justify-content-between mb-3">
                                <!-- <button class="btn btn-primary" @click.prevent="addNewColorTissue()">
                                  Adicionar cor
                                </button> -->
                                <div class="adc-img">
                                  <label
                                    :for="`uploadImage_${tecidoCor['id']}`"
                                    class="button-adc"
                                    >Adicionar do computador</label
                                  >
                                  <input
                                    :id="`uploadImage_${tecidoCor['id']}`"
                                    type="file"
                                    @change="
                                      uploadFile(
                                        tecido['tecido']['nome'],
                                        tecidoCor['id'],
                                      )
                                    "
                                    :ref="`${tecido['tecido']['nome']}_${tecidoCor['id']}`"
                                  />
                                </div>
                                <button
                                  class="btn btn-primary"
                                  @click.prevent="
                                    openModalGalleryTecidoClick(
                                      tecido['tecido']['nome'],
                                      tecidoCor['id'],
                                    )
                                  "
                                >
                                  Abrir da galeria
                                </button>
                              </div>
                              <div>
                                <!-- Imagens da galeria já inseridas do produto -->
                                <template
                                  v-if="
                                    tecidoCor['tecido_cor_imagem'].length > 0
                                  "
                                >
                                  <div class="d-flex gap-3">
                                    <div
                                      class="card"
                                      v-for="img in tecidoCor[
                                        'tecido_cor_imagem'
                                      ]"
                                      :key="img"
                                    >
                                      <img
                                        class="card-img-top img-fluid img-tecido"
                                        :src="`${baseURL}/public/gallery/${img['galeria_imagem']['filename']}`"
                                      />
                                      <div class="py-2 text-center">
                                        <div class="btn-remover">
                                          <a
                                            href
                                            class="fw-medium"
                                            @click.prevent="
                                              removeImageTissue(img['id'])
                                            "
                                            >Remover</a
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </template>
                              </div>
                            </div>
                          </div>
                        </b-card-text>
                      </b-tab>
                    </b-tabs>
                  </b-tab>
                </b-tabs>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!--  MODALS  -->

    <!-- Selecionar uma imagem da galeria -->
    <b-modal
      v-model="openModalGallery"
      id="modal-xl"
      size="xl"
      title="Galeria de imagens pré selecionadas"
      title-class="font-18"
      hide-footer
    >
      <GalleryModal v-if="openModalGallery" @selectImage="selectImageGallery" />
    </b-modal>

    <!-- Selecionar uma imagem da galeria para o tecido -->
    <b-modal
      v-model="openModalGalleryTecido"
      id="modal-xl"
      size="xl"
      title="Galeria de imagens pré selecionadas"
      title-class="font-18"
      hide-footer
    >
      <GalleryModal
        v-if="openModalGalleryTecido"
        :tecido="openModalGalleryTecidoChoose.tecido"
        :cor="openModalGalleryTecidoChoose.cor"
        @selectImage="selectImageGalleryTecido"
      />
    </b-modal>

    <!-- Cadastrar uma categoria -->
    <b-modal
      v-model="showModal"
      title="Nova categoria"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer
    >
      <form>
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Digite um nome para a categoria</label>
              <input
                id="name"
                type="text"
                class="form-control"
                placeholder="Nome da categoria"
                v-model="vCategory.name"
                :class="{
                  'is-invalid': submittedCategory && v$.vCategory.name.$error,
                }"
              />
              <div
                v-if="submittedCategory && v$.vCategory.name.$error"
                class="invalid-feedback"
              >
                <span v-if="v$.vCategory.name.required.$message">{{
                  v$.vCategory.name.required.$message
                }}</span>
              </div>
            </div>
            <div class="mb-3">
              <b-form-checkbox
                v-model="categoryChecked"
                name="check-button"
                switch
              >
                Cadastrar essa categoria como <b>ativa</b>
              </b-form-checkbox>
            </div>
          </div>
        </div>

        <div class="text-end pt-2 mt-3">
          <b-button variant="light" @click="showModal = false">Fechar</b-button>
          <b-button
            type="submit"
            variant="success"
            class="ms-1"
            @click.prevent="createCategory"
            >Criar</b-button
          >
        </div>
      </form>
    </b-modal>

    <!-- Escolher o tecido -->
    <b-modal
      v-model="showModalTissue"
      title="Tecido"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer
    >
      <form>
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Escolha um tecido</label>
              <p
                v-if="tissueModal['colors'].length === 0"
                class="text-danger text-center"
              >
                Precisar ter no minimo uma cor
              </p>
              <p
                v-if="tissueModal['select'] === ''"
                class="text-danger text-center"
              >
                Precisar ter no minimo um tecido
              </p>
              <!-- {{ tissueModal }} -->
              <b-form-select
                class="form-select"
                id="formrow-inputState"
                :options="
                  tissues.map((c) => {
                    return c['nome'];
                  })
                "
                v-model="tissueModal['select']"
                type="text"
              ></b-form-select>
            </div>
            <div class="mb-3">
              <!-- Lista as cores -->
              <template v-if="tissueColors.length >= 1">
                <div
                  class="row mb-2"
                  v-for="(color, index) in tissueModal['colors']"
                  :key="color.id"
                >
                  <div class="col-sm-5">
                    <b-form-select
                      class="form-select"
                      id="formrow-inputState"
                      :options="
                        tissueColors.map((c) => {
                          return c['nome'];
                        })
                      "
                      v-model="color.name"
                      type="text"
                    ></b-form-select>
                  </div>

                  <div class="col-sm-4">
                    <div
                      class="color-div"
                      :style="`background-color: ${
                        tissueColors.filter((c) => {
                          if (c['nome'] === color.name) return c;
                        })[0]['hex']
                      } ;`"
                      v-if="color.name"
                    >
                      .
                    </div>
                  </div>

                  <div class="col-sm-3">
                    <div class="mt-2 mt-md-0 d-grid">
                      <input
                        type="button"
                        class="btn btn-primary btn-block inner"
                        value="Remover"
                        @click.prevent="deleteTissueColor(index)"
                      />
                    </div>
                  </div>
                </div>
              </template>
              <button
                class="btn mt-3 btn-primary"
                @click.prevent="addTissueColor"
              >
                Nova cor
              </button>
            </div>
          </div>
        </div>

        <div class="text-end pt-2 mt-3">
          <b-button variant="light" @click="showModalTissue = false"
            >Fechar</b-button
          >
          <b-button
            type="submit"
            variant="success"
            class="ms-1"
            @click.prevent="saveNewTissue"
          >
            Adicionar
          </b-button>
        </div>
      </form>
    </b-modal>
  </Layout>
</template>

<style>
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.buttom-adc {
  cursor: pointer;
  width: unset;
}

.buttom-adc label {
  margin-bottom: 0;
  cursor: pointer;
}

.remover-img {
  justify-content: space-between;
}

.upload .btn {
  padding: 5px;
  margin-bottom: 5px;
}

.color-div {
  width: 100% !important;
  height: 100%;
  color: transparent;
}

.adc-img input {
  position: absolute;
  visibility: hidden;
}

.img-tecido {
  width: 170px !important;
}

.width100 {
  width: 100% !important;
}

.button-adc {
  font-size: 13px !important;
  font-weight: 400 !important;
  padding: 7px !important;
}
</style>
