import {api} from "@/service";

export const state = {
  newDiscount: {},
  discounts: [],
  findDiscount: {},
  findProductsDiscount: {}
}

export const mutations = {
  UPDATE_NEW_DISCOUNT: (state, value) => state.newDiscount = value,
  UPDATE_DISCOUNTS: (state, value) => state.discounts = value,
  UPDATE_FIND_DISCOUNT: (state, value) => state.findDiscount = value,
  UPDATE_FIND_PRODUCTS_DISCOUNT: (state, value) => state.findProductsDiscount = value,
}

export const actions = {
  addDiscount({commit}, payload) {
    return api.post('/promocao/add', payload).then(
      (response) => {
        if (response.status === 201) commit('UPDATE_NEW_DISCOUNT', response.data)
      }
    )
  },
  getDiscounts({commit}) {
    return api.get('/promocao').then(
      (response) => {
        if (response.status === 200) commit('UPDATE_DISCOUNTS', response.data)
      }
    )
  },
  changeStatusDiscount({dispatch}, payload) {
    return api.put(`/promocao/${payload['id']}/status`, payload['data']).then(
      (response) => {
        if (response.status === 200) dispatch('getDiscounts')
      }
    )
  },
  deleteDiscount({dispatch}, idDiscount) {
    return api.delete(`/promocao/delete/${idDiscount}`).then(
      (response) => {
        if (response.status === 200) dispatch('getDiscounts')
      }
    )
  },
  findDiscountById({commit}, idDiscount) {
    return api.get(`/promocao/findby/${idDiscount}`).then(
      (response) => {
        if (response.status === 200) commit('UPDATE_FIND_DISCOUNT', response.data)
      }
    )
  },
  getProductsByDiscount({commit}, code) {
    return api.get(`/promocao/${code}/all?page=1&size=9999999`).then(
      (response) => {
        if (response.status === 200) commit('UPDATE_FIND_PRODUCTS_DISCOUNT', response.data['products'])
      }
    )
  },

  // eslint-disable-next-line no-empty-pattern
  addProductsInDiscount({}, payload) {
    return api.post(`/promocao/${payload['id']}/produto`, payload['data']).then(
      () => {
      }
    )
  },
  // eslint-disable-next-line no-empty-pattern
  updateDiscountInformation({}, payload) {
    return api.put(`/promocao/${payload['id']}/information`, payload['data']).then(() => {
    })
  }
}