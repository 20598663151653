<script>
export default {
  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
    },
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/estoril_logo.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/estoril_logo.png" alt height="17" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/estoril_logo.png" alt height="12" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/estoril_logo.png" alt height="40" />
            </span>
          </router-link>
        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>

      <div class="d-flex">
        <b-dropdown
          right
          variant="black"
          toggle-class="header-item"
          menu-class="dropdown-menu-end"
        >
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user"
              src="@/assets/images/users/avatardefault_92824.png"
              alt="Header Avatar"
            />
          </template>
          <!-- item-->
          <router-link to="/logout" class="dropdown-item text-danger">
            <i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ $t('navbar.dropdown.henry.list.logout') }}
          </router-link>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>
