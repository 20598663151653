<template>
  <div
      @dragenter.prevent="toggleActive"
      @dragleave.prevent="toggleActive"
      @dragover.prevent
      @drop.prevent="toggleActive"
      :class="{ 'active-dropzone': active }"
      class="dropzone"
  >
    <!--    <span>Arraste uma imagem </span>-->
    <!--    <span>ou</span>-->
    <label for="dropzoneFile">{{ labelText ? labelText : 'Selecione um arquivo' }}</label>
    <input type="file" id="dropzoneFile" class="dropzoneFile btn btn-primary"/>
  </div>
</template>

<script>
import {ref} from "vue";

export default {
  name: "dropzone",
  props: ['labelText'],
  setup() {
    const active = ref(false);
    const toggleActive = () => {
      active.value = !active.value;
    };
    return {active, toggleActive};
  },
};
</script>

<style scoped lang="scss">
.dropzone {
  // width: 400px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  border: 2px dashed #169CBF;
  background-color: #fff;
  transition: 0.3s ease all;

  label {
    padding: 8px 12px;
    color: #fff;
    background-color: #169CBF;
    transition: 0.3s ease all;
    margin-bottom: 0;
    cursor: pointer;
  }

  input {
    display: none;
  }
}

.active-dropzone {
  color: #fff;
  border-color: #fff;
  background-color: #169CBF;

  label {
    background-color: #fff;
    color: #169CBF;
  }
}
</style>