<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useVuelidate from "@vuelidate/core";
import {ref} from "vue";
import DropZone from "@/components/widgets/dropZone";
import {bannerMethods, categoryMethods, discountMethods, productsMethods} from "@/state/helpers";
import {mapState} from "vuex";
import {filterProducts} from "../../../../utils";
// eslint-disable-next-line no-unused-vars
import {nanoid} from 'nanoid'
// eslint-disable-next-line no-unused-vars
import Swal from "sweetalert2";
import {baseURL} from "@/service";

/**
 * Product-cart component
 */
export default {
  page: {
    title: "Adicionar Promoção",
    meta: [{name: "description", content: appConfig.description}],
  },
  components: {Layout, PageHeader, DropZone},
  /**
   * Configura o dropzone
   * @returns {{drop: drop, selectedFile: selectedFile, dropzoneFile: Ref<UnwrapRef<*[]>>, v$: Ref<Validation> | Ref<Validation<ValidationArgs, {[key in keyof ValidationArgs]: any}>> | ComputedRef<any>}}
   */
  setup() {
    let dropzoneFile = ref([]);
    // let imagesThumbail = reactive({})
    const drop = (e) => {
      dropzoneFile.value[0] = e.dataTransfer.files[0];
    };
    const selectedFile = () => {
      const f = document.querySelector('.dropzoneFile').files[0]
      dropzoneFile.value[0] = f
      // imagesThumbail[f.name + '_' + f.size] = {thumbnail: false}
    };
    return {dropzoneFile, drop, selectedFile, v$: useVuelidate()};
  },
  computed: {
    ...mapState('category', ['category']),
    ...mapState('product', ['products']),
    ...mapState('discount', ['findDiscount', 'findProductsDiscount'])
  },
  data() {
    return {
      title: "Adicionar Promoção",
      items: [
        {
          text: "Loja",
          href: "/",
        },
        {
          text: 'Descontos',
          href: '/discounts'
        },
        {
          text: "Adicionar Promoção",
          active: true,
        },
      ],
      nome: '',
      tipoVinculo: 'Produto',
      categoriaParaVinculo: '',
      searchProductString: '',
      productsResults: [],
      productsSelecteded: [],
      status: 'Desativado',

      // Separa
      discount: null,
      baseURL,
      changeBanner: true
    };
  },
  async created() {
    await this.getCategory()

    const idDiscount = this.$route['params']['id']
    if (idDiscount) {
      if (this.$route['params']['discount']) {
        this.discount = JSON.parse(this.$route['params']['discount'])
      } else {
        await this.findDiscountById(Number(idDiscount))
        this.discount = this.findDiscount
      }

      if (this.discount['tipo'] === 'CATEGORIA') {
        this.categoriaParaVinculo = this.discount['desconto_categoria'][0]['categoria']['nome']
      } else {
        await this.getProductsByDiscount(this.discount['codigo'])
      }
      this.status = this.discount['ativo'] ? 'Ativado' : 'Desativado'
      this.tipoVinculo = this.discount['tipo'] === 'CATEGORIA' ? 'Categoria' : 'Produto'
      this.nome = this.discount['nome']
    }

    await this.getProducts()
  },
  methods: {
    ...categoryMethods,
    ...productsMethods,
    ...discountMethods,
    ...bannerMethods,

    async updateDiscount() {
      const payload = {
        id: this.discount['id'],
        data: {
          "nome": this.nome,
          "ativo": this.status === "Ativado"
        }
      }

      // Atualiza as informações da promoção
      await this.updateDiscountInformation(payload)

      if (this.discount['tipo'] === 'PRODUTO') {
        // Adiciona os novos produtos se houver
        if (this.productsSelecteded.length > 0) {
          await this.addProductsInDiscount({
            id: this.discount['id'],
            data: {products: this.productsSelecteded.map(p => p['id'])}
          })
        }
      }

      if (this.dropzoneFile.length > 0) {
        // Altera a imagem do banner caso seja necessario
        let formData = new FormData();
        formData.append('file', this.dropzoneFile[0])
        formData.append('name', this.nome)
        formData.append('desconto_id', this.discount['id'])

        await this.addBanner(formData)
      }

      Swal.fire("Promoção", "Promoção atualizada com sucesso!", "success");
      this.dropzoneFile = []

      if (this.discount['tipo'] === 'PRODUTO') await this.getProductsByDiscount(this.discount['codigo'])
      await this.findDiscountById(Number(this.discount['id']))
      this.discount = this.findDiscount
    },

    /**
     * Adicona produto
     * @param product
     */
    addProduct(product) {
      this.productsResults = []
      this.searchProductString = ''
      this.productsSelecteded.push(product)
    },
    /**
     * Remove o produto do selecionado
     * @param product
     */
    removeProduct(product) {
      const tmp = []

      this.productsSelecteded.forEach(i => {
        if (i !== product) tmp.push(i)
      })

      this.productsSelecteded = tmp
    },
    /**
     * Gera uma url apartir do file
     * @param file
     * @returns {string}
     */
    getUrl(file) {
      return URL.createObjectURL(file)
    },
    /**
     * Remove uma imagem da lista
     * @param file
     */
    removeFile(file) {
      const tmp = []
      this.dropzoneFile.forEach(f => {
        if (f !== file) tmp.push(f)
      })
      this.dropzoneFile = tmp
    },
    async removeFileFromBanner(banner) {
      await this.removeBannerById(banner['idBanner'])

      await this.findDiscountById(Number(banner['desconto_id']))
      this.discount = this.findDiscount
    }
  },
  watch: {
    searchProductString: function (value) {
      if (value === '') this.productsResults = []
      else this.productsResults = filterProducts(value, this.products)
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>

    <form action="" class="" v-if="discount">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Altere as informações da promoção</h4>
              <p class="card-title-desc">
                Insira corretamente os dados para a alteração da promoção
              </p>

              <form @submit.prevent="updateDiscount">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="row">
                      <!-- Nome do desconto para identificação -->
                      <div class="col-6">
                        <div class="mb-3">
                          <label for="productname">Nome</label>
                          <input
                              id="productname"
                              v-model="nome"
                              name="name"
                              type="text"
                              class="form-control"
                              :maxlength="50"
                          />
                          <!-- Max Length -->
                          <div v-if="nome" class="text-center">
                            <p
                                class="badge position-absolute"
                                :class="{ 'bg-success': nome.length !== 50,
                            'bg-danger': nome.length === 50 }"
                            >{{ nome.length }} / 50</p>
                          </div>
                        </div>
                      </div>
                      <!-- Status do desconto -->
                      <div class="col-6">
                        <div class="mb-3">
                          <label class="control-label">Status do desconto</label>
                          <div class="d-flex justify-content-between align-content-center gap-1">
                            <div style="width: 100%">
                              <b-form-select
                                  class="form-select"
                                  id="formrow-inputState"
                                  :options="['Ativado', 'Desativado']"
                                  v-model="status"
                                  type="text"
                              ></b-form-select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Seleciona uma imagem -->
                    <div class="mb-3">
                      <label for="">Inserir imagem</label>
                      <DropZone label-text="Selecionar do Computador" @drop.prevent="drop" @change="selectedFile"/>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <!-- seleciona o tipo de vinculo -->
                    <div class="mb-3">
                      <label class="control-label">Tipo de vinculo</label>
                      <div class="d-flex justify-content-between align-content-center gap-1">
                        <div style="width: 100%">
                          <b-form-select
                              class="form-select"
                              id="formrow-inputState"
                              :options="['Categoria', 'Produto']"
                              disabled
                              v-model="tipoVinculo"
                              type="text"
                          ></b-form-select>
                        </div>
                      </div>
                    </div>

                    <!-- CASO TENHA ESCOLHIDO CATEGORIA COMO VINCULO -->
                    <div class="mb-3" v-if="tipoVinculo === 'Categoria'">
                      <label class="control-label">Escolha uma categoria</label>
                      <div class="d-flex justify-content-between align-content-center gap-1">
                        <div style="width: 100%">
                          <b-form-select
                              class="form-select"
                              id="formrow-inputState"
                              :options="category.map((c) => {return c['nome']})"
                              v-model="categoriaParaVinculo"
                              disabled
                              type="text"
                          ></b-form-select>
                        </div>
                      </div>
                    </div>
                    <!-- CASO TENHA ESCOLHIDO PRODUTO COMO VINCULO -->
                    <div class="mb-3" v-if="tipoVinculo === 'Produto'">
                      <label class="control-label">Procure pelo os produtos</label>
                      <form class="app-search">
                        <div class="position-relative">
                          <input
                              type="text"
                              class="form-control"
                              v-model="searchProductString"
                              :placeholder="$t('navbar.search.text')"
                          />
                          <span class="bx bx-search-alt"></span>
                          <div class="search-results" v-if="productsResults.length > 0">
                            <div class="search-result-item" v-for="product in productsResults" :key="product['id']">
                              <p @click="addProduct(product)"> {{ product['nome'] }}</p></div>
                          </div>
                        </div>
                      </form>
                      <div>
                        <label for="">Produtos já na promoção:</label>
                        <ul v-if="findProductsDiscount.length > 0">
                          <li
                              v-for="product in findProductsDiscount"
                              :key="product['id']"
                          >
                            {{ product['nome'] }}
                          </li>
                        </ul>

                        <label for="">Produtos a serem adicionados na promoção:</label>
                        <ul v-if="productsSelecteded.length > 0">
                          <li
                              v-for="product in productsSelecteded"
                              :key="product['id']"
                              @click="removeProduct(product)"
                              style="cursor:pointer;"
                          >
                            {{ product['nome'] }}
                          </li>
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="row justify-content-center">
                  <p class="text-center py-4"><strong>Atenção! A proporção ideal para as imagens é: ?</strong></p>
                  <!-- IMAGENS DO BANNER-->
                  <div class="col col-md-3" v-for="file in dropzoneFile" :key="file.name">
                    <div class="body-card">
                      <img
                          style="width: 100%; height: 100%"
                          class="rounded me-2"
                          :alt="file.name"
                          width=""
                          :src="getUrl(file)"
                          data-holder-rendered="true"
                      />
                    </div>
                    <div class="remover-img text-center" @click.prevent="removeFile(file)">
                      <a href="">Remover imagem</a>
                    </div>
                  </div>
                  <!-- IMAGENS DO BANNER-->
                  <template v-if="discount['banner'].length > 0 && dropzoneFile.length === 0">
                    <label for="" class="text-center">Imagem atual da promoção</label>
                    <div class="col col-md-3" v-for="file in discount['banner']" :key="file.name">
                      <div class="body-card">
                        <img
                            style="width: 100%; height: 100%"
                            class="rounded me-2"
                            :alt="file.name"
                            width=""
                            :src="`${baseURL}/public/banner/${file['filename']}`"
                            data-holder-rendered="true"
                        />
                      </div>
                      <!--                      <div class="remover-img text-center" @click.prevent="removeFileFromBanner(file)">-->
                      <!--                        <a href="">Remover imagem</a>-->
                      <!--                      </div>-->
                    </div>
                  </template>
                </div>

                <!-- Botoẽs -->
                <div class="mt-2 text-center">
                  <button type="submit" class="btn btn-primary me-1">
                    Salvar
                  </button>
                  <button class="btn btn-secondary" @click.prevent="this.$router.push({name: 'Discounts'})">
                    Cancelar
                  </button>
                </div>
              </form>


            </div>
          </div>
        </div>

      </div>

    </form>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
.search-results {
  position: absolute;
  margin-top: 5px;
  width: 100%;
  background-color: #fff;
  overflow: auto;
  height: 200px;
  z-index: 1;
  -webkit-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.1);
}

.search-result-item {
  font-size: 14px;
  cursor: pointer;
}

.search-result-item p {
  margin: 0;
  padding: 5px 20px;
}

.search-result-item:hover {
  background-color: #3d9bef;
  color: #fff;
}
</style>

