<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import {discountMethods} from "@/state/helpers";
import {mapState} from "vuex";
import JMPagination from "@/components/JMPagination";
import {baseURL} from "@/service";

/**
 * Customers component
 */
export default {
  page: {
    title: 'Banners',
    meta: [{name: 'description', content: appConfig.description}],
  },
  components: {JMPagination, Layout, PageHeader},
  data() {
    return {
      status: 'sim',
      title: 'Banners',
      items: [
        {
          text: 'Painel de controle',
          href: '/',
        },
        {
          text: 'Banners',
          active: true,
        },
      ],
      baseURL,
      pageOfItems: [],
      discountLocal: [],
      searchDiscount: ''
    };
  },
  computed: {
    ...mapState('discount', ['discounts'])
  },
  async created() {
    await this.getDiscounts()

    this.discountLocal = this.discounts
  },
  methods: {
    ...discountMethods,
    onChangePage(pageOfItems) {
      this.pageOfItems = pageOfItems
    },
    async discountChangeStatus(discount) {
      await this.changeStatusDiscount({id: discount['id'], data: {status: !discount['ativo']}})
    },
    async deleteDiscountById(discount) {
      await this.deleteDiscount(discount['id'])
    },
    editDiscount(discount) {
      this.$router.push({name: 'Edit Discount', params: {id: discount['id'], discount: JSON.stringify(discount)}})
    }
  },
  watch: {
    discounts: function (value) {
      this.discountLocal = value
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2 justify-content-end">
              <div class="col-sm-8">
                <div class="text-sm-end">
                  <router-link to="discount/add">
                    <button
                        type="button"
                        class="btn btn-primary btn-rounded mb-2 me-2"
                    >
                      <i class="mdi mdi-plus me-1"></i> Adicionar nova promoção
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap align-middle">
                <thead>
                <tr>
                  <th>Ativo</th>
                  <th>Imgem</th>
                  <th>Nome</th>
                  <th>Vinculo com</th>
                  <th>Status</th>
                  <th>Ação</th>
                </tr>
                </thead>
                <tbody>

                <!-- Tem que mudar os customers.item -->
                <!-- { "id": 1, "nome": "40% OFF em roupas", "codigo": "CAR44O", "valor": 40, "ativo": false, "tipo": "CATEGORIA", "banner": [] } -->
                <tr v-for="discount in pageOfItems" :key="discount['id']">
                  <!--                  {{ discount }}-->
                  <td>
                    <div class="form-check font-size-16">
                      <input
                          :id="`customCheck${discount['id']}`"
                          type="checkbox"
                          class="form-check-input"
                          :checked="discount['ativo']"
                          @click="discountChangeStatus(discount)"
                      />
                      <label
                          class="form-check-label"
                          :for="`customCheck${discount['id']}`"
                      >&nbsp;</label
                      >
                    </div>
                  </td>
                  <td>
                    <img
                        v-if="discount['banner'].length > 0"
                        class="rounded me-2"
                        alt="200x200"
                        width="47"
                        height="31"
                        :src="`${baseURL}/public/banner/${discount['banner'][0]['filename']}`"
                        data-holder-rendered="true"
                    />
                    <template v-else>Sem imagem</template>
                  </td>
                  <td>{{ discount['nome'] }}</td>

                  <td>{{ discount['tipo'] }}</td>
                  <td>{{ discount['ativo'] ? 'Ativo' : 'Inativo' }}</td>

                  <td>
                    <b-dropdown
                        class="card-drop"
                        variant="white"
                        right
                        toggle-class="p-0"
                        menu-class="dropdown-menu-end"
                    >
                      <template v-slot:button-content>
                        <i class="mdi mdi-dots-horizontal font-size-18"></i>
                      </template>

                      <b-dropdown-item @click="editDiscount(discount)">
                        <i class="fas fa-pencil-alt text-success me-1"></i>
                        Editar
                      </b-dropdown-item>

                      <b-dropdown-item @click.prevent="deleteDiscountById(discount)">
                        <i class="fas fa-trash-alt text-danger me-1"></i>
                        Deletar
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
                </tbody>
              </table>
              <div v-if="pageOfItems.length === 0" class="text-center my-3">
                <a href="javascript:void(0);" class="text-success">
                  <i class="bx bx-loader bx-spin font-size-18 align-middle me-2"></i>
                  Buscando as promoções
                </a>
              </div>
            </div>

            <!-- Paginação -->
            <JMPagination :items="discountLocal" :page-size="10" @changePage="onChangePage"/>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
