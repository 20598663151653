<script>

import router from '../../index'
import {authMethods} from "@/state/helpers";

export default {
  created() {
    this.logout()
    router.push('/login');
  },
  methods: {
    ...authMethods
  }
}
</script>