<script>
import Layout from '../../layouts/auth';
import {
  authMethods,
  notificationMethods,
} from '@/state/helpers';
import {mapState} from 'vuex';

import appConfig from '@/app.config';
import {required, email, helpers} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

/**
 * Login component
 */
export default {
  setup() {
    return {v$: useVuelidate()};
  },
  page: {
    title: 'Login',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  data() {
    return {
      email: 'admin@email.com',
      password: 'admin123',
      submitted: false,
      authError: null,
      isAuthError: false,
    };
  },
  validations: {
    email: {
      required: helpers.withMessage('E-mail é obrigatório!', required),
      email: helpers.withMessage('Por favor digite seu e-mail', email),
    },
    password: {
      required: helpers.withMessage('Por favor digite sua senha', required),
    },
  },
  computed: {
    ...mapState('authentication', ['currentUser', 'errorLogin']),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    ...authMethods,
    ...notificationMethods,
    async tryToLogIn() {
      this.submitted = true;
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      } else {
        // Some com a notificação de erro se houver
        this.isAuthError = false

        // Faz o login
        await this.login({email: this.email, password: this.password})

        if (this.currentUser) {
          this.$router.push({name: 'default'})
          this.clear()
        } else if (this.errorLogin) {
          this.error('Email ou senha incorretos')
        }
      }
    },
  },
  mounted() {
  },
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft">
            <div class="row">
              <div class="text-primary py-4 text-center">
                <h3>Olá, seja bem vindo novamente</h3>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <b-alert
                v-model="isAuthError"
                variant="danger"
                class="mt-3"
                dismissible
            >{{ authError }}
            </b-alert
            >
            <div v-if="notification.message" :class="'alert ' + notification.type">
              {{ notification.message }}
            </div>

            <b-form class="p-2" @submit.prevent="tryToLogIn">
              <b-form-group
                  class="mb-3"
                  id="input-group-1"
                  label="E-mail"
                  label-for="input-1"
              >
                <b-form-input
                    id="input-1"
                    v-model="email"
                    type="text"
                    placeholder="Digite seu e-mail"
                    :class="{
                    'is-invalid': submitted && v$.email.$error,
                  }"
                ></b-form-input>
                <div
                    v-for="(item, index) in v$.email.$errors"
                    :key="index"
                    class="invalid-feedback"
                >
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </b-form-group>

              <b-form-group
                  class="mb-3"
                  id="input-group-2"
                  label="Senha"
                  label-for="input-2"
              >
                <b-form-input
                    id="input-2"
                    v-model="password"
                    type="password"
                    placeholder="Digite sua senha"
                    :class="{
                    'is-invalid': submitted && v$.password.$error,
                  }"
                ></b-form-input>
                <div
                    v-if="submitted && v$.password.$error"
                    class="invalid-feedback"
                >
                  <span v-if="v$.password.required.$message">{{
                      v$.password.required.$message
                    }}</span>
                </div>
              </b-form-group>

              <!--              <b-form-checkbox-->
              <!--                  class="form-check me-2 mt-0"-->
              <!--                  id="customControlInline"-->
              <!--                  name="checkbox-1"-->
              <!--                  value="accepted"-->
              <!--                  unchecked-value="not_accepted"-->
              <!--              >Lembre meu login-->
              <!--              </b-form-checkbox>-->

              <div class="mt-3 d-grid">
                <b-button type="submit" variant="primary" class="btn-block">Entrar</b-button>
              </div>

            </b-form>
          </div>
        </div>
        <div class="mt-5 text-center">
          <p>© {{ new Date().getFullYear() }} yuTech</p>
        </div>
      </div>
    </div>
  </Layout>
</template>
