<template>
  <div class="galery">
    <div class="row">
      <div
        class="col-6 col-sm-4 col-md-3 col-xl-2"
        v-for="image in galleryImages"
        :key="image['id']"
      >
        <div class="card">
          <div class="body-card-galery">
            <div class="galery-img">
              <img
                style="width: 100%"
                class="rounded"
                alt="300x300"
                width=""
                :src="`${baseURL}/public/gallery/${image['filename']}`"
                data-holder-rendered="true"
              />
            </div>
          </div>
          <div
            v-if="tecido"
            class="btn-usar"
            @click.prevent="selectImage({ image, tecido, cor })"
          >
            <a href="">Usar</a>
          </div>
          <div v-else class="btn-usar" @click.prevent="selectImage(image)">
            <a href="">Usar</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { galleryMethods } from '@/state/helpers';
import { mapState } from 'vuex';
import { baseURL } from '@/service';

export default {
  name: 'GalleryModal',
  props: {
    tecido: String,
    cor: String,
  },
  emits: ['selectImage'],
  data() {
    return {
      baseURL,
    };
  },
  async created() {
    await this.getAllImagesGallery();
  },
  computed: {
    ...mapState('gallery', ['galleryImages']),
  },
  methods: {
    ...galleryMethods,
    selectImage(image) {
      this.$emit('selectImage', image);
    },
  },
};
</script>

<style scoped></style>
