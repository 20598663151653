<script>
import DropZone from '@/components/widgets/dropZone';
import { required, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import { mapState, mapGetters } from 'vuex';
import {
  categoryMethods,
  galleryMethods,
  notificationMethods,
  productsMethods,
  tissueMethods,
} from '@/state/helpers';
import Swal from 'sweetalert2';
import { reactive, ref } from 'vue';
import GalleryModal from '@/components/GalleryModal';
import { baseURL } from '@/service';

export default {
  page: {
    title: 'Adicionar Produto',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  /**
   * Configura o dropzone
   * @returns {{drop: drop, selectedFile: selectedFile, dropzoneFile: Ref<UnwrapRef<*[]>>, v$: Ref<Validation> | Ref<Validation<ValidationArgs, {[key in keyof ValidationArgs]: any}>> | ComputedRef<any>}}
   */
  setup() {
    let dropzoneFile = ref([]);
    let imagesThumbail = reactive({});
    const drop = (e) => {
      dropzoneFile.value.push(e.dataTransfer.files[0]);
    };
    const selectedFile = () => {
      const f = document.querySelector('.dropzoneFile').files[0];
      dropzoneFile.value.push(f);
      imagesThumbail[f.name + '_' + f.size] = { thumbnail: false };
    };
    return {
      dropzoneFile,
      drop,
      selectedFile,
      v$: useVuelidate(),
      imagesThumbail,
    };
  },
  computed: {
    // Busca o state da categoria
    ...mapState('category', ['category']),
    ...mapState('product', ['newProduct']),
    ...mapState('tissue', ['tissues', 'tissueColors', 'newTissueColor']),
    ...mapGetters('gallery', ['getInserted', 'getInsertedTissueGallery']),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  created() {
    // Busca todas as categorias
    this.getCategory();

    // Busca todos os tecidos
    this.getTissues();

    // Busca todas as cores
    this.getTissueColors();
  },
  components: {
    GalleryModal,
    DropZone,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: 'Adicionar Produto',
      items: [
        {
          text: 'Dashboard',
          href: '/',
        },
        {
          text: 'Produtos',
          href: '/products',
        },
        {
          text: 'Adicionar produto',
          active: true,
        },
      ],
      product: {
        name: '',
        price: '',
        category: '',
        description: '',
      },
      vCategory: {
        name: '',
      },
      submitted: false,
      submittedProduct: false,
      submittedCategory: true,
      // files: [],
      selectCategory: [],
      showModal: false,
      showModalTissue: false,
      openModalGallery: false,
      categoryChecked: true,
      imagesFromGallery: [],
      baseURL,
      // Tissue
      tissue: {
        has: 'Sim',
        new: {},
      },
      tissueModal: {
        select: '',
        colors: [],
      },
      openModalGalleryTecido: false,
      openModalGalleryTecidoChoose: { tecido: '', cor: '' },
    };
  },
  /**
   * Validações do formulario de cadastro
   */
  validations: {
    product: {
      name: {
        required: helpers.withMessage('Nome do produto obrigatório', required),
      },
      price: {
        required: helpers.withMessage(
          'Valor do produto é obrigatório',
          required,
        ),
      },
      category: {
        required: helpers.withMessage(
          'Você precisa escolher uma categoria',
          required,
        ),
      },
      description: {
        required: helpers.withMessage(
          'Preencha uma descrição para o produto',
          required,
        ),
      },
    },
    vCategory: {
      name: {
        required: helpers.withMessage(
          'Você precisa escolher uma categoria',
          required,
        ),
      },
    },
  },
  methods: {
    ...categoryMethods,
    ...galleryMethods,
    ...productsMethods,
    ...notificationMethods,
    ...tissueMethods,

    /**
     * Cadastra um produto
     */
    async productAdd() {
      this.submitted = true;
      this.submittedProduct = true;
      this.v$.$touch();
      this.clear();

      /**
       * Valida se os campos do produto não está preenchido
       */
      if (
        this.v$.$errors.filter((e) => {
          if (e['$propertyPath'] === 'product.name') return true;
          if (e['$propertyPath'] === 'product.price') return true;
          if (e['$propertyPath'] === 'product.category') return true;
          if (e['$propertyPath'] === 'product.description') return true;
        }).length > 0
      ) {
        this.submittedProduct = false;
        return;
      } else {
        /**
         * Antes de cadastrar o produto precisamos registrar as imagens na galeria
         */
        if (this.dropzoneFile.length > 0)
          await this.insertImageG(this.dropzoneFile);

        // Monta o array com os id do tecido
        let tissueIds = [];
        if (this.tissue['has'] === 'Sim') {
          Object.keys(this.tissue['new']).forEach((t) => {
            tissueIds.push(this.getTissueId(t));
          });
        }

        /**
         * Cadastra o produto
         */
        await this.addProduct({
          nome: this.product.name,
          descricao: this.product.description,
          valor: Number(this.product.price.replace('R$ ', '')), // Remover a string R$ da variavel
          categoria_id: this.category.filter((c) => {
            if (c['nome'] === this.product.category) return true; // Precisamos filtrar a categoria pelo o nome para pega o id
          })[0]['id'],
          tecido: this.tissue['has'] === 'Sim' ? true : false,
          tecido_id: tissueIds,
        });

        // Criou o produto com sucesso
        if (this.newProduct) {
          if (this.tissue['has'] === 'Sim') {
            // Percorrer pelo os tecidos do produto na ordem que está inserida devido o idproduto_tecido
            this.newProduct['product']['produto_tecido'].forEach(async (pt) => {
              const { idproduto_tecido, tecido } = pt;
              console.log(idproduto_tecido, tecido);
              // Busca as cores e imagens do tecido
              const it = this.tissue['new'][tecido['nome']];

              for (const nameColor of it) {
                console.log(idproduto_tecido, nameColor);
                await this.addColorTissue({
                  cor_id: this.getColorId(nameColor['name']),
                  idproduto_tecido,
                });

                console.log(idproduto_tecido, nameColor, this.newTissueColor);
                // Id da corTecidoProduto
                const { id } = this.newTissueColor;
                nameColor[nameColor['name']]['galleryImages'].forEach(
                  async (img) => {
                    await this.addColorImageTissue({
                      galeria_imagem_id: img['id'],
                      tecido_cor_id: id,
                    });
                  },
                );
              }
            });
          }

          /**
           * Vincula a imagem da galeria ao produto
           */
          if (
            this.dropzoneFile.length > 0 ||
            this.imagesFromGallery.length > 0
          ) {
            const ids = [
              // Preenche com os ids das imagens novas da galeria
              ...this.getInserted.map((c) => {
                return c['id'];
              }),
              // Preenche com os ids das imagens selecionadas da galeria
              ...this.imagesFromGallery.map((c) => {
                return c['id'];
              }),
            ];
            console.log(ids);
            await this.addImageToProduct(ids);
          }

          await this.clearInsertImageGallery();
          Swal.fire(
            'Produto adicionado',
            'Seu produto foi adicionado com sucesso!',
            'success',
          );
          this.tissue['new'] = {};
          this.clearForm();
        } else this.error('Nâo foi possivel cadastrar o produto');

        this.submitted = false;
        this.submittedProduct = false;
      }
    },
    /**
     * Adiciona as imagens a galeria
     * @param payload
     * @returns {Promise<void>}
     */
    async insertImageG(payload) {
      for (const file of payload) {
        let formData = new FormData();
        formData.append('file', file);
        await this.insertImageGallery(formData);
      }
    },
    /**
     * Limpa o formulario de cadastro
     */
    clearForm() {
      this.product.name = '';
      this.product.price = '';
      this.product.category = '';
      this.product.description = '';
      this.dropzoneFile = [];
      this.imagesFromGallery = [];
    },
    /**
     * Cria uma nova categoria
     * @returns {Promise<void>}
     */
    async createCategory() {
      this.submittedCategory = true;
      this.v$.$touch();

      /**
       * Verifica se o input da categoria não foi preenchido
       */
      if (
        this.v$.$errors.filter((e) => {
          if (e['$propertyPath'] === 'vCategory.name') return true;
        }).length > 0
      ) {
        return;
      } else {
        await this.addCategory({
          nome: this.vCategory.name,
          status: this.categoryChecked,
        });

        // TODO: Implementar a captura de erros
        this.showModal = false;
        this.submittedCategory = false;
        this.product.category = this.vCategory.name;
      }
    },
    /**
     * Gera uma url apartir do file
     * @param file
     * @returns {string}
     */
    getUrl(file) {
      return URL.createObjectURL(file);
    },
    /**
     * Remove uma imagem da lista
     * @param file
     */
    removeFile(file) {
      const tmp = [];
      this.dropzoneFile.forEach((f) => {
        if (f !== file) tmp.push(f);
      });
      this.dropzoneFile = tmp;
    },
    removeImageFromGallery(image) {
      const tmp = [];

      this.imagesFromGallery.forEach((i) => {
        if (i !== image) tmp.push(i);
      });

      this.imagesFromGallery = tmp;
    },
    /**
     * Recebe por evento a imagem selecionada no modal da galeria
     * @param image
     */
    selectImageGallery(image) {
      console.log(image);
      this.imagesFromGallery.push(image);
      this.imagesThumbail[image['filename']] = { thumbnail: false };
      this.openModalGallery = false;
    },
    /**
     * Recebe por evento a imagem selecionada no modal da galeria
     * @param image
     */
    changeThumbnail(value) {
      if (value.name) {
        Object.keys(this.imagesThumbail).forEach((c) => {
          if (c === value.name + '_' + value.size) {
            this.imagesThumbail[c]['thumbnail'] = true;
          } else this.imagesThumbail[c]['thumbnail'] = false;
        });
      }
    },
    /**
     * Remove a cor do modal
     */
    addTissueColor() {
      this.tissueModal.colors.push({ name: '' });
    },
    deleteTissueColor(index) {
      this.tissueModal.colors.splice(index, 1);
    },
    saveNewTissue() {
      if (this.tissueModal['colors']) {
        this.tissue['new'][this.tissueModal['select']] = this.tissueModal[
          'colors'
        ].map((c) => {
          return {
            name: c['name'],
            [c['name']]: {
              localFiles: [],
              galleryImages: [],
            },
          };
        });
      }
      this.showModalTissue = false;
      this.tissueModal = {
        select: '',
        colors: [],
      };
    },
    async uploadFile(tecido, cor) {
      // Captura a imagem
      const imageFile = this.$refs[`${tecido}_${cor}`][0].files[0];

      // Salva a imagen na galeria
      let formData = new FormData();
      formData.append('file', imageFile);
      await this.insertImageGalleryTissue(formData);

      // Percorre pelo o tamanho do tecido exemplo:
      // x Tecido tem x cores o for vai percorrer pela as cores para termos o id do ARRAY
      for (let i = 0; i < this.tissue['new'][tecido].length; i++) {
        // Verifica se a cor é igual ao que vai receber a imagem do upload
        if (this.tissue['new'][tecido][i]['name'] === cor) {
          // Adiciona a imagem na localFiles
          // localFiles => Imagens que vai ser inseridas ainda na galeria
          this.tissue['new'][tecido][i][cor]['galleryImages'].push(
            this.getInsertedTissueGallery,
          );
        }
      }
      console.log(this.tissue['new']);
    },
    selectImageGalleryTecido(data) {
      const { image, tecido, cor } = data;

      for (let i = 0; i < this.tissue['new'][tecido].length; i++) {
        if (this.tissue['new'][tecido][i]['name'] === cor) {
          this.tissue['new'][tecido][i][cor]['galleryImages'].push(image);
        }
      }

      this.openModalGalleryTecido = false;
    },
    openModalGalleryTecidoClick(tecido, cor) {
      this.openModalGalleryTecidoChoose.tecido = tecido;
      this.openModalGalleryTecidoChoose.cor = cor;
      this.openModalGalleryTecido = true;
    },
    getTissueId(tecido) {
      let id;
      this.tissues.forEach((tt) => {
        if (tecido === tt['nome']) id = tt['id'];
      });
      return id;
    },
    getColorId(name) {
      let id;
      this.tissueColors.forEach((color) => {
        if (color['nome'] === name) id = color['id'];
      });

      return id;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div
              v-if="notification.message"
              :class="'alert ' + notification.type"
            >
              {{ notification.message }}
            </div>
            {{ newProduct }}
            <h4 class="card-title">Informações básicas</h4>
            <p class="card-title-desc">
              Insira corretamente os dados para adicionar um novo produto
            </p>
            <!-- Formulario -->
            <form @submit.prevent="productAdd">
              <div class="row">
                <div class="col-sm-6">
                  <!-- Nome do produto -->
                  <div class="mb-3">
                    <label for="productname">Nome</label>
                    <input
                      id="productname"
                      v-model="product.name"
                      name="name"
                      type="text"
                      class="form-control"
                      :maxlength="50"
                      :class="{
                        'is-invalid': submitted && v$.product.name.$error,
                      }"
                    />
                    <!-- Max Length -->
                    <div v-if="product.name" class="text-center">
                      <p
                        class="badge position-absolute"
                        :class="{
                          'bg-success': product.name.length !== 50,
                          'bg-danger': product.name.length === 50,
                        }"
                      >
                        {{ product.name.length }} / 50
                      </p>
                    </div>

                    <!-- Validação errada -->
                    <div
                      v-if="submitted && v$.product.name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="v$.product.name.required.$message">{{
                        v$.product.name.required.$message
                      }}</span>
                    </div>
                  </div>

                  <div class="row">
                    <!-- Preço do produto -->
                    <div class="col-sm-6">
                      <div class="mb-3">
                        <label for="price">Preço</label>
                        <input
                          id="price"
                          name="price"
                          v-model="product.price"
                          v-maska="[
                            'R$ #.##',
                            'R$ ##.##',
                            'R$ ###.##',
                            'R$ ####.##',
                            'R$ ##',
                            'R$ ###',
                          ]"
                          :class="{
                            'is-invalid': submitted && v$.product.price.$error,
                          }"
                          type="text"
                          class="form-control"
                        />
                        <div
                          v-if="submitted && v$.product.price.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="v$.product.price.required.$message">{{
                            v$.product.price.required.$message
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- Define se tem ou não tecido -->
                    <div class="col-sm-6">
                      <div class="mb-3">
                        <label>Tem tecido?</label>
                        <b-form-select
                          class="form-select"
                          id="formrow-inputState"
                          :options="['Sim', 'Não']"
                          v-model="tissue['has']"
                        ></b-form-select>
                      </div>
                    </div>
                  </div>

                  <!-- Descrição do produto -->
                  <div class="mb-3">
                    <label for="productdesc">Descrição do produto</label>
                    <textarea
                      id="productdesc"
                      class="form-control"
                      rows="6"
                      :class="{
                        'is-invalid':
                          submitted && v$.product.description.$error,
                      }"
                      v-model="product.description"
                    ></textarea>
                    <div
                      v-if="submitted && v$.product.description.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="v$.product.description.required.$message">{{
                        v$.product.description.required.$message
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <!--Categoria do produto-->
                  <div class="mb-3">
                    <label class="control-label">Categoria</label>
                    <div
                      class="d-flex justify-content-between align-content-center gap-1"
                    >
                      <div style="width: 100%">
                        <b-form-select
                          class="form-select"
                          id="formrow-inputState"
                          :options="
                            category.map((c) => {
                              return c['nome'];
                            })
                          "
                          v-model="product.category"
                          type="text"
                          :class="{
                            'is-invalid':
                              submitted && v$.product.category.$error,
                          }"
                        ></b-form-select>
                        <div
                          v-if="submitted && v$.product.category.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="v$.product.category.required.$message">{{
                            v$.product.category.required.$message
                          }}</span>
                        </div>
                      </div>
                      <b-button variant="primary" @click="showModal = true">
                        Criar
                      </b-button>
                    </div>
                  </div>

                  <!-- Upload das imagens  -->
                  <div
                    class="d-flex justify-content-between upload align-items-center"
                  >
                    <label>Inserir imagem</label>
                    <button
                      class="btn btn-primary"
                      @click.prevent="openModalGallery = true"
                    >
                      Abrir da galeria
                      {{
                        imagesFromGallery.length > 0
                          ? imagesFromGallery.length
                          : ''
                      }}
                    </button>
                  </div>
                  <DropZone @drop.prevent="drop" @change="selectedFile" />
                  <!--                  <span v-for="file in dropzoneFile" :key="file.name" class="file-info">{{ file.name }}<br/></span>-->
                  <p
                    class="text-center py-1"
                    style="font-size: 10px !important"
                  >
                    <strong>
                      Atenção! A proporção ideal para as imagens é: 300px300px,
                      400px400px, 500px500px etc</strong
                    >
                    {{ showModalTissue }}
                  </p>
                  <div class="row mt-2">
                    <!-- Imagens do dropzone -->
                    <div
                      class="col-6"
                      v-for="file in dropzoneFile"
                      :key="file.name"
                    >
                      <div class="card">
                        <img
                          class="card-img-top img-fluid"
                          :src="getUrl(file)"
                          :alt="file.name"
                        />
                        <!-- <input
                          type="checkbox"
                          @click="changeThumbnail(file)"
                          :checked="
                            imagesThumbail[file.name + '_' + file.size][
                              'thumbnail'
                            ]
                          "
                        /> -->
                        <div class="py-2 text-center">
                          <div class="btn-remover">
                            <a
                              href
                              class="fw-medium"
                              @click.prevent="removeFile(file)"
                              >Remover
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Images selecionadas da galeria -->
                    <div
                      class="col-6"
                      v-for="image in imagesFromGallery"
                      :key="image['id']"
                    >
                      <div class="card">
                        <img
                          class="card-img-top img-fluid"
                          :src="`${baseURL}/public/gallery/${image['filename']}`"
                          :alt="image['filnename']"
                        />
                        <div class="py-2 text-center">
                          <div class="btn-remover">
                            <a
                              href
                              class="fw-medium"
                              @click.prevent="removeImageFromGallery(image)"
                              >Remover
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Tecido -->
              <div class="row" v-if="tissue['has'] === 'Sim'">
                <h4 class="text-center mt-3">
                  Preenche as informações dos tecidos
                </h4>
                <button
                  class="btn btn-primary mb-3"
                  @click.prevent="showModalTissue = true"
                >
                  Adicionar
                </button>
                <div class="col-sm-12">
                  <!-- MONTA AS TAB COM OS NOME DOS TECIDO -->
                  <b-tabs
                    justified
                    nav-class="nav-tabs-custom"
                    content-class="p-3 text-muted"
                  >
                    <b-tab v-for="t in Object.keys(tissue['new'])" :key="t">
                      <template v-slot:title>
                        <span>{{ t }}</span>
                      </template>
                      <!-- MONTA AS TAB COM O NOME DAS CORES DO TECIDO -->
                      <b-tabs
                        pills
                        vertical
                        nav-class="p-0"
                        content-class="pt-0 px-2 text-muted width100"
                      >
                        <b-tab
                          v-for="color in tissue['new'][t]"
                          :title="color['name']"
                          :key="color['name']"
                          title-item-class="mb-2"
                          style="border-color: blue"
                        >
                          <b-card-text>
                            <div class="row">
                              <div class="col">
                                <div
                                  class="d-flex justify-content-between mb-3"
                                >
                                  <div class="adc-img">
                                    <label
                                      :for="`uploadImage_${t}_${color['name']}`"
                                      class="button-adc"
                                      >Adicionar do computador</label
                                    >
                                    <input
                                      :id="`uploadImage_${t}_${color['name']}`"
                                      type="file"
                                      @change="uploadFile(t, color['name'])"
                                      :ref="`${t}_${color['name']}`"
                                    />
                                  </div>

                                  <button
                                    @click.prevent="
                                      openModalGalleryTecidoClick(
                                        t,
                                        color['name'],
                                      )
                                    "
                                    class="btn btn-primary"
                                  >
                                    Abrir da galeria
                                  </button>
                                </div>
                                <div>
                                  <div>
                                    <!-- IMAGENS DA GALERIA -->
                                    <template
                                      v-if="
                                        color[color['name']]['galleryImages']
                                          .length > 0
                                      "
                                    >
                                      <div class="d-flex gap-3">
                                        <div
                                          class="card"
                                          v-for="img in color[color['name']][
                                            'galleryImages'
                                          ]"
                                          :key="img"
                                        >
                                          <img
                                            class="card-img-top img-fluid img-tecido"
                                            :src="`${baseURL}/public/gallery/${img['filename']}`"
                                          />
                                        </div>
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-card-text>
                        </b-tab>
                      </b-tabs>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>

              <!-- Botoẽs -->
              <div class="mt-2 text-center">
                <b-button
                  variant="primary"
                  :disabled="submittedProduct"
                  @click="productAdd"
                >
                  <i
                    class="bx bx-hourglass bx-spin font-size-16 align-middle me-2"
                    v-if="submittedProduct"
                  ></i>
                  {{ submittedProduct ? 'Adicionando...' : 'Salvar' }}
                </b-button>
                <button
                  class="btn btn-secondary ms-2"
                  @click.prevent="this.$router.push({ name: 'Products' })"
                  :disabled="submittedProduct"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!--  MODALS  -->

    <!-- Selecionar uma imagem da galeria -->
    <b-modal
      v-model="openModalGallery"
      id="modal-xl"
      size="xl"
      title="Galeria de imagens pré selecionadas"
      title-class="font-18"
      hide-footer
    >
      <GalleryModal v-if="openModalGallery" @selectImage="selectImageGallery" />
    </b-modal>

    <!-- Selecionar uma imagem da galeria para o tecido -->
    <b-modal
      v-model="openModalGalleryTecido"
      id="modal-xl"
      size="xl"
      title="Galeria de imagens pré selecionadas"
      title-class="font-18"
      hide-footer
    >
      <GalleryModal
        v-if="openModalGalleryTecido"
        :tecido="openModalGalleryTecidoChoose.tecido"
        :cor="openModalGalleryTecidoChoose.cor"
        @selectImage="selectImageGalleryTecido"
      />
    </b-modal>

    <!-- Cadastrar uma categoria -->
    <b-modal
      v-model="showModal"
      title="Nova categoria"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer
    >
      <form>
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Digite um nome para a categoria</label>
              <input
                id="name"
                type="text"
                class="form-control"
                placeholder="Nome da categoria"
                v-model="vCategory.name"
                :class="{
                  'is-invalid': submittedCategory && v$.vCategory.name.$error,
                }"
              />
              <div
                v-if="submittedCategory && v$.vCategory.name.$error"
                class="invalid-feedback"
              >
                <span v-if="v$.vCategory.name.required.$message">{{
                  v$.vCategory.name.required.$message
                }}</span>
              </div>
            </div>
            <div class="mb-3">
              <b-form-checkbox
                v-model="categoryChecked"
                name="check-button"
                switch
              >
                Cadastrar essa categoria como <b>ativa</b>
              </b-form-checkbox>
            </div>
          </div>
        </div>

        <div class="text-end pt-2 mt-3">
          <b-button variant="light" @click="showModal = false">Fechar</b-button>
          <b-button
            type="submit"
            variant="success"
            class="ms-1"
            @click.prevent="createCategory"
            >Criar</b-button
          >
        </div>
      </form>
    </b-modal>

    <!-- Escolher o tecido -->
    <b-modal
      v-model="showModalTissue"
      title="Tecido"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer
    >
      <form>
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Escolha um tecido</label>
              <p
                v-if="tissueModal['colors'].length === 0"
                class="text-danger text-center"
              >
                Precisar ter no minimo uma cor
              </p>
              <p
                v-if="tissueModal['select'] === ''"
                class="text-danger text-center"
              >
                Precisar ter no minimo um tecido
              </p>
              <!-- {{ tissueModal }} -->
              <b-form-select
                class="form-select"
                id="formrow-inputState"
                :options="
                  tissues.map((c) => {
                    return c['nome'];
                  })
                "
                v-model="tissueModal['select']"
                type="text"
              ></b-form-select>
            </div>
            <div class="mb-3">
              <!-- Lista as cores -->
              <template v-if="tissueColors.length >= 1">
                <div
                  class="row mb-2"
                  v-for="(color, index) in tissueModal['colors']"
                  :key="color.id"
                >
                  <div class="col-sm-5">
                    <b-form-select
                      class="form-select"
                      id="formrow-inputState"
                      :options="
                        tissueColors.map((c) => {
                          return c['nome'];
                        })
                      "
                      v-model="color.name"
                      type="text"
                    ></b-form-select>
                  </div>

                  <div class="col-sm-4">
                    <div
                      class="color-div"
                      :style="`background-color: ${
                        tissueColors.filter((c) => {
                          if (c['nome'] === color.name) return c;
                        })[0]['hex']
                      } ;`"
                      v-if="color.name"
                    >
                      .
                    </div>
                  </div>

                  <div class="col-sm-3">
                    <div class="mt-2 mt-md-0 d-grid">
                      <input
                        type="button"
                        class="btn btn-primary btn-block inner"
                        value="Remover"
                        @click.prevent="deleteTissueColor(index)"
                      />
                    </div>
                  </div>
                </div>
              </template>
              <button
                class="btn mt-3 btn-primary"
                @click.prevent="addTissueColor"
              >
                Nova cor
              </button>
            </div>
          </div>
        </div>

        <div class="text-end pt-2 mt-3">
          <b-button variant="light" @click="showModalTissue = false"
            >Fechar</b-button
          >
          <b-button
            type="submit"
            variant="success"
            class="ms-1"
            @click.prevent="saveNewTissue"
          >
            Adicionar
          </b-button>
        </div>
      </form>
    </b-modal>
  </Layout>
</template>

<style>
.upload .btn {
  padding: 5px;
  margin-bottom: 5px;
}

.color-div {
  width: 100% !important;
  height: 100%;
  color: transparent;
}

.adc-img input {
  position: absolute;
  visibility: hidden;
}

.img-tecido {
  width: 170px !important;
}

.width100 {
  width: 100% !important;
}

.button-adc {
  font-size: 13px !important;
  font-weight: 400 !important;
  padding: 7px !important;
}
</style>
