import {createWebHistory, createRouter} from "vue-router";

import routes from './routes'
import jwtDecode from "jwt-decode";

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  },
})

/**
 * Aplica a lógica de validação da acessToken
 */
router.beforeEach((routeTo, routeFrom, next) => {

  // Verifica se a rota precisa estar autenticado
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired)

  // Se a rota não precisa continua
  if (!authRequired) return next()

  // Captura a accessToken do usuario
  const accessToken = localStorage.getItem('access-token');

  // Se não tem nenhuma token salva então redireciona o usuario para o login
  if (!accessToken) redirectLogin()
  else {
    // Verifica se a token ainda está na validade
    if (jwtDecode(accessToken)) {
      // Se sim continua
      return next()
    } else redirectLogin()
  }

  // Redireciona o usuario para o login
  function redirectLogin() {
    next({name: 'login', query: {redirectFrom: routeTo.fullPath}})
  }
})

export default router
