<script>
import {SimpleBar} from 'simplebar-vue3';
import SideNav from './side-nav';
import {layoutComputed} from '@/state/helpers';

/**
 * Sidebar component
 */
export default {
  components: {SimpleBar, SideNav},
  props: {
    isCondensed: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...layoutComputed,
  },
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
    };
  },
  methods: {},
  watch: {
    // $route: {
    //   handler: "onRoutechange",
    //   immediate: true,
    //   deep: true,
    // },
    // type: {
    //   immediate: true,
    //   handler(newVal, oldVal) {
    //     if (newVal !== oldVal) {
    //       switch (newVal) {
    //           /*  */
    //         default:
    //           document.body.setAttribute("data-sidebar", "dark");
    //           break;
    //       }
    //     }
    //   },
    // },
    // width: {
    //   immediate: true,
    //   /*  */
    // },
  },
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="vertical-menu">
    <SimpleBar
        v-if="!isCondensed"
        :settings="settings"
        class="h-100"
        ref="currentMenu"
        id="my-element"
    >
      <SideNav/>
    </SimpleBar>

    <SimpleBar v-else class="h-100">
      <SideNav/>
    </SimpleBar>
  </div>
  <!-- Left Sidebar End -->
</template>
