import { mapState, mapActions } from 'vuex';

// export const authComputed = {
//   ...mapState('auth', {
//     currentUser: (state) => state.currentUser,
//   }),
//   ...mapGetters('auth', ['loggedIn']),
// }

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader,
  }),
};

/**
 * Estoril
 */
export const authMethods = mapActions('authentication', ['login', 'logout']);
export const productsMethods = mapActions('product', [
  'getProducts',
  'addProduct',
  'addImageToProduct',
  'addImageToProductUpdate',
  'removeImageToProduct',
  'changeStatusProduct',
  'deleteProduct',
  'findById',
  'updateProductById',
  'changeThumbnailId',
]);
export const categoryMethods = mapActions('category', [
  'getCategory',
  'addCategory',
  'changeStatusCategory',
]);
export const galleryMethods = mapActions('gallery', [
  'insertImageGallery',
  'clearInsertImageGallery',
  'getAllImagesGallery',
  'removeImageFromGallery',
  'insertImageGalleryTissue',
]);
export const discountMethods = mapActions('discount', [
  'addDiscount',
  'getDiscounts',
  'changeStatusDiscount',
  'deleteDiscount',
  'findDiscountById',
  'getProductsByDiscount',
  'addProductsInDiscount',
  'updateDiscountInformation',
]);
export const bannerMethods = mapActions('banner', [
  'addBanner',
  'removeBannerById',
]);
export const informationMethods = mapActions('information', [
  'getInformations',
]);
export const tissueMethods = mapActions('tissue', [
  'getTissues',
  'getTissueColors',
  'addColorTissue',
  'addColorImageTissue',
  'removeColorImageTissue',
  'addTissueForProduct',
  'deleteTissueProduct',
  'deleteTissueProductColor',
]);

export const notificationMethods = mapActions('notification', [
  'success',
  'error',
  'clear',
]);
