<script>
import Layout from '@/router/layouts/main';
import PageHeader from '../../../components/page-header.vue';
import JMPagination from '@/components/JMPagination.vue';
import { mapState } from 'vuex';
import { categoryMethods } from '../../../state/helpers';
import { filterCategory } from '../../../../utils';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

export default {
  name: 'category',
  page: {
    title: 'Produtos',
    meta: [{ name: 'description', content: 'Lista de categorias' }],
  },
  components: { Layout, PageHeader, JMPagination },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      items: [
        {
          text: 'Painel',
          href: '/',
        },
        {
          text: 'Categorias',
          active: true,
        },
      ],
      pageOfItems: [],
      searchCategory: '',
      categoryLocal: [],
      showModal: false,
      submittedCategory: false,
      categoryChecked: true,
      vCategory: {
        name: '',
      },
    };
  },
  validations: {
    vCategory: {
      name: {
        required: helpers.withMessage(
          'Você precisa escolher uma categoria',
          required,
        ),
      },
    },
  },
  async created() {
    await this.getCategory();

    this.categoryLocal = this.category;
  },
  computed: {
    ...mapState('category', ['category']),
  },
  methods: {
    ...categoryMethods,
    /**
     * Muda o numero da paginação
     * @param pageOfItems
     */
    onChangePage(pageOfItems) {
      this.pageOfItems = pageOfItems;
    },
    /**
     * Atualiza o status de um produto
     * @param category
     * @returns {Promise<void>}
     */
    async categoryChangeStatus(category) {
      await this.changeStatusCategory({
        id: category['id'],
        data: { status: !category['status'] },
      });
    },
    /**
     * Delete uma produto pelo o id
     * @param category
     * @returns {Promise<void>}
     */
    async deleteCategoryById(category) {
      await this.deleteCategory(category['id']);
    },
    /**
     * Chama a view que edita a categoria
     * @param category
     */
    editCategory(category) {
      this.$router.push({
        name: 'Edit Category',
        params: {
          id: category['id'],
          prcategoryoduct: JSON.stringify(category),
        },
      });
    },
    /**
     * Cria uma nova categoria
     * @returns {Promise<void>}
     */
    async createCategory() {
      this.submittedCategory = true;
      this.v$.$touch();

      /**
       * Verifica se o input da categoria não foi preenchido
       */
      if (
        this.v$.$errors.filter((e) => {
          if (e['$propertyPath'] === 'vCategory.name') return true;
        }).length > 0
      ) {
        return;
      } else {
        await this.addCategory({
          nome: this.vCategory.name,
          status: this.categoryChecked,
        });

        // TODO: Implementar a captura de erros
        this.showModal = false;
        this.submittedCategory = false;
      }
    },
  },
  watch: {
    /**
     * Filtra as categorias
     */
    searchCategory: function (value) {
      if (value === '') this.categoryLocal = this.category;
      else this.categoryLocal = filterCategory(value, this.category);
    },
    /**
     * Atualiza a lista de categorias
     */
    category: function (value) {
      this.categoryLocal = value;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-4">
                <div class="search-box me-2 mb-2 d-inline-block">
                  <!-- Pesquisa -->
                  <div class="position-relative">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Pesquisa..."
                      v-model="searchCategory"
                    />
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <!-- Botão para a tela de adicionar categoria -->
              <div class="col-sm-8">
                <div class="text-sm-end">
                  <button
                    type="button"
                    class="btn btn-primary btn-rounded mb-2 me-2"
                    @click="showModal = true"
                  >
                    <i class="mdi mdi-plus me-1"></i> Adicionar categoria
                  </button>
                </div>
              </div>
            </div>
            <!-- Tabela com as informações dos produtos -->
            <div class="table-responsive">
              <table class="table table-centered table-nowrap align-middle">
                <thead>
                  <tr>
                    <th>Ativo</th>
                    <th>Nome</th>
                    <th>Ação</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Lista os produtos -->
                  <tr
                    v-for="category in pageOfItems"
                    :key="category.id"
                    class="tr-custom"
                  >
                    <td>
                      <div class="form-check font-size-16">
                        <input
                          :id="`customCheck${category.id}`"
                          type="checkbox"
                          class="form-check-input"
                          :checked="category['status']"
                          @click="categoryChangeStatus(category)"
                        />
                        <label
                          class="form-check-label"
                          :for="`customCheck${category.id}`"
                          >&nbsp;</label
                        >
                      </div>
                    </td>

                    <td>{{ category['nome'] }}</td>

                    <!-- Ações do produto -->
                    <td>
                      <b-dropdown
                        class="card-drop"
                        variant="white"
                        right
                        toggle-class="p-0"
                        menu-class="dropdown-menu-end"
                      >
                        <template v-slot:button-content>
                          <i class="mdi mdi-dots-horizontal font-size-18"></i>
                        </template>

                        <b-dropdown-item
                          @click="categoryChangeStatus(category)"
                        >
                          <i
                            class="dripicons-clockwise me-1"
                            :class="
                              category['status']
                                ? 'text-danger'
                                : 'text-success'
                            "
                          ></i>
                          {{ category['status'] ? 'Desativar' : 'Ativar' }}
                        </b-dropdown-item>

                        <!-- <b-dropdown-item @click="editCategory(category)">
                          <i class="fas fa-pencil-alt text-success me-1"></i>
                          Editar
                        </b-dropdown-item> -->

                        <!-- TODO: Validar se a categoria tem produto vinculado
                          <b-dropdown-item @click="deleteCategoryById(category)">
                          <i class="fas fa-trash-alt text-danger me-1"></i>
                          Deletar
                        </b-dropdown-item> -->
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="pageOfItems.length === 0" class="text-center my-3">
                <a href="javascript:void(0);" class="text-success">
                  <i
                    class="bx bx-loader bx-spin font-size-18 align-middle me-2"
                  ></i>
                  Buscando as categorias
                </a>
              </div>
            </div>
            <!-- Paginação -->
            <JMPagination
              :items="categoryLocal"
              :page-size="10"
              @changePage="onChangePage"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- MODALS -->

    <!-- Cadastrar uma categoria -->
    <b-modal
      v-model="showModal"
      title="Nova categoria"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer
    >
      <form>
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Digite um nome para a categoria</label>
              <input
                id="name"
                type="text"
                class="form-control"
                placeholder="Nome da categoria"
                v-model="vCategory.name"
                :class="{
                  'is-invalid': submittedCategory && v$.vCategory.name.$error,
                }"
              />
              <div
                v-if="submittedCategory && v$.vCategory.name.$error"
                class="invalid-feedback"
              >
                <span v-if="v$.vCategory.name.required.$message">{{
                  v$.vCategory.name.required.$message
                }}</span>
              </div>
            </div>
            <div class="mb-3">
              <b-form-checkbox
                v-model="categoryChecked"
                name="check-button"
                switch
              >
                Cadastrar essa categoria como <b>ativa</b>
              </b-form-checkbox>
            </div>
          </div>
        </div>

        <div class="text-end pt-2 mt-3">
          <b-button variant="light" @click="showModal = false">Fechar</b-button>
          <b-button
            type="submit"
            variant="success"
            class="ms-1"
            @click.prevent="createCategory"
            >Criar</b-button
          >
        </div>
      </form>
    </b-modal>
  </Layout>
</template>
