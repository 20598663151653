import {api} from "@/service";

export const state = {
  informations: {}
}

export const mutations = {
  UPDATE_INFORMATIONS: (state, value) => state.informations = value
}

export const actions = {
  getInformations({commit}) {
    return api.get('/information').then(
      (response) => {
        if (response.status === 200) commit('UPDATE_INFORMATIONS', response.data)
      }
    )
  }
}