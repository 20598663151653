<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';

import { productsMethods } from '@/state/helpers';
import { mapState } from 'vuex';
import JMPagination from '@/components/JMPagination';
import { filterProducts } from '../../../../utils';

export default {
  page: {
    title: 'Produtos',
    meta: [{ name: 'description', content: 'Lista os produtos' }],
  },
  components: { JMPagination, Layout, PageHeader },
  data() {
    return {
      title: 'Produtos',
      items: [
        {
          text: 'Loja',
          href: '/',
        },
        {
          text: 'Produtos',
          active: true,
        },
      ],
      pageOfItems: [],
      searchProduct: '',
      productsLocal: [],
    };
  },
  computed: {
    ...mapState('product', ['products']),
  },
  async created() {
    // Busca a lista de produtos
    await this.getProducts();

    this.productsLocal = this.products;
  },
  methods: {
    ...productsMethods,
    /**
     * Muda o numero da paginação
     * @param pageOfItems
     */
    onChangePage(pageOfItems) {
      this.pageOfItems = pageOfItems;
    },
    /**
     * Atualiza o status de um produto
     * @param product
     * @returns {Promise<void>}
     */
    async productChangeStatus(product) {
      await this.changeStatusProduct({
        id: product['id'],
        status: !product['status'],
      });
    },
    /**
     * Delete uma produto pelo o id
     * @param product
     * @returns {Promise<void>}
     */
    async deleteProductById(product) {
      await this.deleteProduct(product['id']);
    },
    /**
     * Chama a view que edita o produto
     * @param product
     */
    editProduct(product) {
      this.$router.push({
        name: 'Edit Product',
        params: { id: product['id'], product: JSON.stringify(product) },
      });
    },
  },
  watch: {
    /**
     * Procura o texto digitado no nome dos produtos
     * @param value
     */
    searchProduct: function (value) {
      if (value === '') this.productsLocal = this.products;
      else this.productsLocal = filterProducts(value, this.products);
    },
    /**
     * Atualiza a nova lista
     * @param value
     */
    products: function (value) {
      this.productsLocal = value;
    },
  },
};
</script>

<template>
  <Layout>
    <!--  Breadcrump  -->
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-4">
                <div class="search-box me-2 mb-2 d-inline-block">
                  <!-- Pesquisa -->
                  <div class="position-relative">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Pesquisa..."
                      v-model="searchProduct"
                    />
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <!-- Botão para a tela de adicionar produto -->
              <div class="col-sm-8">
                <div class="text-sm-end">
                  <router-link to="/products/add">
                    <button
                      type="button"
                      class="btn btn-primary btn-rounded mb-2 me-2"
                    >
                      <i class="mdi mdi-plus me-1"></i> Adicionar produto
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
            <!-- Tabela com as informações dos produtos -->
            <div class="table-responsive">
              <table class="table table-centered table-nowrap align-middle">
                <thead>
                  <tr>
                    <th>Ativo</th>
                    <th>Nome</th>
                    <th>Descrição</th>
                    <th>Preço</th>
                    <th>Categoria</th>
                    <th>Possui tecido</th>
                    <th>Possui foto</th>
                    <th>Ação</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Lista os produtos -->
                  <tr
                    v-for="product in pageOfItems"
                    :key="product.id"
                    class="tr-custom"
                  >
                    <td>
                      <div class="form-check font-size-16">
                        <input
                          :id="`customCheck${product.id}`"
                          type="checkbox"
                          class="form-check-input"
                          :checked="product['status']"
                          @click="productChangeStatus(product)"
                        />
                        <label
                          class="form-check-label"
                          :for="`customCheck${product.id}`"
                          >&nbsp;</label
                        >
                      </div>
                    </td>

                    <td>{{ product['nome'] }}</td>
                    <td>
                      <p>{{ product['descricao'] }}</p>
                    </td>
                    <td>
                      {{
                        Number(product['valor']).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })
                      }}
                    </td>
                    <td>{{ product['categoria']['nome'] }}</td>
                    <td>{{ product['tecido'] ? 'Sim' : 'Não' }}</td>
                    <td>
                      {{
                        product['produto_galeria'].length >= 1 ? 'Sim' : 'Não'
                      }}
                    </td>

                    <!-- Ações do produto -->
                    <td>
                      <b-dropdown
                        class="card-drop"
                        variant="white"
                        right
                        toggle-class="p-0"
                        menu-class="dropdown-menu-end"
                      >
                        <template v-slot:button-content>
                          <i class="mdi mdi-dots-horizontal font-size-18"></i>
                        </template>

                        <b-dropdown-item @click="productChangeStatus(product)">
                          <i
                            class="dripicons-clockwise me-1"
                            :class="
                              product['status'] ? 'text-danger' : 'text-success'
                            "
                          ></i>
                          {{ product['status'] ? 'Desativar' : 'Ativar' }}
                        </b-dropdown-item>

                        <b-dropdown-item @click="editProduct(product)">
                          <i class="fas fa-pencil-alt text-success me-1"></i>
                          Editar
                        </b-dropdown-item>

                        <b-dropdown-item @click="deleteProductById(product)">
                          <i class="fas fa-trash-alt text-danger me-1"></i>
                          Deletar
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="pageOfItems.length === 0" class="text-center my-3">
                <a href="javascript:void(0);" class="text-success">
                  <i
                    class="bx bx-loader bx-spin font-size-18 align-middle me-2"
                  ></i>
                  Buscando os produtos
                </a>
              </div>
            </div>

            <!-- Paginação -->
            <JMPagination
              :items="productsLocal"
              :page-size="10"
              @changePage="onChangePage"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.tr-custom td p {
  margin: 0;
  padding: 0;
}

.tr-custom td p {
  text-overflow: ellipsis;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
}
</style>
