export const menuItems = [
  {
    id: 6,
    label: 'menuitems.catalogo.text',
    icon: 'bx-right-arrow-alt',
    link: 'https://estorilmoveis.com.br/',
    isUnique: true,
  },
  {
    id: 1,
    label: 'menuitems.dashboards.text',
    icon: 'bx bx-home',
    link: '/',
    isUnique: true,
  },
  {
    id: 2,
    label: 'menuitems.products.text',
    link: '/213',
    icon: 'bx bxs-package',
    subItems: [
      {
        id: 1,
        label: 'menuitems.products.list.products',
        link: '/products',
        parentId: 2,
      },
      {
        id: 2,
        label: 'menuitems.products.list.add',
        link: '/products/add',
        parentId: 2,
      },
    ],
  },
  {
    id: 5,
    label: 'menuitems.category.text',
    icon: 'bx bxs-component',
    subItems: [
      {
        id: 1,
        label: 'menuitems.category.list.category',
        link: '/category',
        parentId: 5,
      },
    ],
  },
  {
    id: 3,
    label: 'menuitems.discount.text',
    icon: 'bx bxs-discount',
    subItems: [
      {
        id: 1,
        label: 'menuitems.discount.list.discounts',
        link: '/discounts',
        parentId: 3,
      },
      {
        id: 2,
        label: 'menuitems.discount.list.add',
        link: '/discount/add',
        parentId: 3,
      },
    ],
  },
  {
    id: 4,
    label: 'menuitems.gallery.text',
    icon: 'bx bx-images',
    subItems: [
      {
        id: 1,
        label: 'menuitems.gallery.list.gallery',
        link: '/gallery',
        parentId: 4,
      },
    ],
  },
];
