import axios from "axios";
import {authHeader} from "@/helpers/auth-header";

export const baseURL = process.env.VUE_APP_BASE_URL;
const axiosInstance = axios.create({
  baseURL,
});

export const api = {
  get(endpoint) {
    return axiosInstance.get(endpoint, {
      headers: authHeader(),
      validateStatus: false,
    });
  },
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body, {
      headers: authHeader(),
      validateStatus: false,
    });
  },
  put(endpoint, body) {
    return axiosInstance.put(endpoint, body, {
      headers: authHeader(),
      validateStatus: false,
    });
  },
  delete(endpoint) {
    return axiosInstance.delete(endpoint, {
      headers: authHeader(),
      validateStatus: false,
    });
  },
  postMultiPart(endpoint, formData) {
    return axiosInstance.post(endpoint, formData, {
      headers: authHeader({"Content-Type": "multipart/form-data"}),
      validateStatus: false,
    });
  },
  signIn(endpoint, body) {
    return axiosInstance.post(endpoint, body, {validateStatus: false});
  },
};