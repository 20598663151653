<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import {galleryMethods} from "@/state/helpers";
import {mapState} from "vuex";
import {baseURL} from "@/service";
import JMPagination from "@/components/JMPagination";

export default {
  page: {
    title: 'Galeria',
    meta: [{name: 'description', content: appConfig.description}],
  },
  components: {JMPagination, Layout, PageHeader},
  computed: {
    ...mapState('gallery', ['galleryImages'])
  },
  data() {
    return {
      title: 'Galeria',
      items: [
        {
          text: 'Painel',
          href: '/',
        },
        {
          text: 'Galeria',
          active: true,
        },
      ],
      baseURL,
      images: null,
      galleryImagesLocal: [],
      pageOfItems: []
    };
  },
  async created() {
    await this.getAllImagesGallery()

    this.galleryImagesLocal = this.galleryImages
  },
  methods: {
    ...galleryMethods,
    async removeImage(image) {
      await this.removeImageFromGallery(image['id'])
    },
    uploadFile() {
      this.images = this.$refs.file.files[0]
      this.submitFile()
    },
    async submitFile() {
      let formData = new FormData()
      formData.append('file', this.images)
      await this.insertImageGallery(formData)
      await this.getAllImagesGallery()
      this.images = null
    },
    /**
     * Muda o numero da paginação
     * @param pageOfItems
     */
    onChangePage(pageOfItems) {
      this.pageOfItems = pageOfItems
    },
  },
  watch: {
    galleryImages: function (value) {
      this.galleryImagesLocal = value
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>

    <div class="adc">
      <div class="row justify-content-center">
        <label class="text-center">Selecione uma imagem</label>
        <div class="col col-sm-8 col-md-6">
          <div class="body-card">
            <div class="adc-img">
              <label for="uploadImage" class="button-adc">Adicionar</label>
              <input id="uploadImage" type="file" @change="uploadFile" ref="file">
            </div>
          </div>
        </div>
        <label class="text-center">
          Atenção! A proporção ideal para as imagens é: 300px300px, 400px400px,
          500px500px etc
        </label>
      </div>

    </div>
    <!-- tentar por eles dentro de um paginação -->
    <div class="galery">
      <div class="row">
        <div class="col-6 col-sm-3 col-md-2" v-for="image in pageOfItems" :key="image['id']">
          <div class="card">
            <div class="body-card-galery">
              <div class="galery-img">
                <img
                    style="width: 100%"
                    class="rounded me-2"
                    alt="200x200"
                    width=""
                    :src="`${baseURL}/public/gallery/${image['filename']}`"
                    data-holder-rendered="true"
                />
              </div>
            </div>
            <div class="body-card-baixo">
              <div class="id">
                <p><strong> ID: </strong> {{ image['id'] }}</p>
              </div>
              <div class="delet" @click="removeImage(image)">
                <i class="bx bxs-trash"></i>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <JMPagination :items="galleryImagesLocal" :page-size="18" @changePage="onChangePage"/>
  </Layout>
</template>

<style scoped>
.adc-img input {
  position: absolute;
  visibility: hidden;
}
</style>
