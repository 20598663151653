<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useVuelidate from "@vuelidate/core";
import {ref} from "vue";
import DropZone from "@/components/widgets/dropZone";
import {bannerMethods, categoryMethods, discountMethods, productsMethods} from "@/state/helpers";
import {mapState} from "vuex";
import {filterProducts} from "../../../../utils";
import {nanoid} from 'nanoid'
import Swal from "sweetalert2";

/**
 * Product-cart component
 */
export default {
  page: {
    title: "Adicionar Promoção",
    meta: [{name: "description", content: appConfig.description}],
  },
  components: {Layout, PageHeader, DropZone},
  /**
   * Configura o dropzone
   * @returns {{drop: drop, selectedFile: selectedFile, dropzoneFile: Ref<UnwrapRef<*[]>>, v$: Ref<Validation> | Ref<Validation<ValidationArgs, {[key in keyof ValidationArgs]: any}>> | ComputedRef<any>}}
   */
  setup() {
    let dropzoneFile = ref([]);
    // let imagesThumbail = reactive({})
    const drop = (e) => {
      dropzoneFile.value[0] = e.dataTransfer.files[0];
    };
    const selectedFile = () => {
      const f = document.querySelector('.dropzoneFile').files[0]
      dropzoneFile.value[0] = f
      // imagesThumbail[f.name + '_' + f.size] = {thumbnail: false}
    };
    return {dropzoneFile, drop, selectedFile, v$: useVuelidate()};
  },
  computed: {
    ...mapState('category', ['category']),
    ...mapState('product', ['products']),
    ...mapState('discount', ['newDiscount']),
  },
  data() {
    return {
      title: "Adicionar Promoção",
      items: [
        {
          text: "Loja",
          href: "/",
        },
        {
          text: 'Descontos',
          href: '/discounts'
        },
        {
          text: "Adicionar Promoção",
          active: true,
        },
      ],
      nome: '',
      tipoVinculo: 'Produto',
      categoriaParaVinculo: '',
      searchProductString: '',
      productsResults: [],
      productsSelecteded: [],
      status: 'Desativado'
    };
  },
  async created() {
    await this.getCategory()

    await this.getProducts()
  },
  methods: {
    ...categoryMethods,
    ...productsMethods,
    ...discountMethods,
    ...bannerMethods,

    async createDiscount() {
      /**
       * Monta o payload de envio
       * @type {{codigo: string, tipo: string, ativo: boolean, data: ({categoria: *}|{produtos: *[]}), valor: number, nome: string}}
       */
      const payload = {
        "nome": this.nome,
        "valor": 0,
        "codigo": nanoid(),
        "tipo": this.tipoVinculo.toUpperCase(),
        "ativo": this.status === 'Ativado',
        "data": this.tipoVinculo === 'Categoria' ? {
          "categoria": this.category.filter(c => {
            if (c['nome'] === this.categoriaParaVinculo) return true
          })[0]['id']
        } : {"produtos": this.productsSelecteded.map(p => p['id'])}
      }

      /**
       * Cria o desconto
       */
      await this.addDiscount(payload)
      console.log(this.newDiscount)

      /**
       * Salva a imagem do banner e vincula ela ao desconto
       */
      let formData = new FormData();
      formData.append('file', this.dropzoneFile[0])
      formData.append('name', this.nome)
      formData.append('desconto_id', this.newDiscount['discount']['id'])

      /**
       * Cria o banner
       */
      await this.addBanner(formData)
      Swal.fire("Promoção", "Promoção cadastrada com sucesso!", "success");

      /**
       * Limpa o form
       */
      this.nome = ''
      this.productsSelecteded = []
      this.dropzoneFile = []
    },

    /**
     * Adicona produto
     * @param product
     */
    addProduct(product) {
      this.productsResults = []
      this.searchProductString = ''
      this.productsSelecteded.push(product)
    },
    /**
     * Remove o produto do selecionado
     * @param product
     */
    removeProduct(product) {
      const tmp = []

      this.productsSelecteded.forEach(i => {
        if (i !== product) tmp.push(i)
      })

      this.productsSelecteded = tmp
    },
    /**
     * Gera uma url apartir do file
     * @param file
     * @returns {string}
     */
    getUrl(file) {
      return URL.createObjectURL(file)
    },
    /**
     * Remove uma imagem da lista
     * @param file
     */
    removeFile(file) {
      const tmp = []
      this.dropzoneFile.forEach(f => {
        if (f !== file) tmp.push(f)
      })
      this.dropzoneFile = tmp
    },
  },
  watch: {
    searchProductString: function (value) {
      if (value === '') this.productsResults = []
      else this.productsResults = filterProducts(value, this.products)
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>

    <form action="" class="">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Adicione as informações da promoção</h4>
              <p class="card-title-desc">
                Insira corretamente os dados para a nova promoção
              </p>

              <form @submit.prevent="createDiscount">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="row">
                      <!-- Nome do desconto para identificação -->
                      <div class="col-6">
                        <div class="mb-3">
                          <label for="productname">Nome</label>
                          <input
                              id="productname"
                              v-model="nome"
                              name="name"
                              type="text"
                              class="form-control"
                              :maxlength="50"
                          />
                          <!-- Max Length -->
                          <div v-if="nome" class="text-center">
                            <p
                                class="badge position-absolute"
                                :class="{ 'bg-success': nome.length !== 50,
                            'bg-danger': nome.length === 50 }"
                            >{{ nome.length }} / 50</p>
                          </div>
                        </div>
                      </div>
                      <!-- Status do desconto -->
                      <div class="col-6">
                        <div class="mb-3">
                          <label class="control-label">Status do desconto</label>
                          <div class="d-flex justify-content-between align-content-center gap-1">
                            <div style="width: 100%">
                              <b-form-select
                                  class="form-select"
                                  id="formrow-inputState"
                                  :options="['Ativado', 'Desativado']"
                                  v-model="status"
                                  type="text"
                              ></b-form-select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Seleciona uma imagem -->
                    <div class="mb-3">
                      <label for="">Inserir imagem</label>
                      <DropZone label-text="Selecionar do Computador" @drop.prevent="drop" @change="selectedFile"/>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <!-- seleciona o tipo de vinculo -->
                    <div class="mb-3">
                      <label class="control-label">Tipo de vinculo</label>
                      <div class="d-flex justify-content-between align-content-center gap-1">
                        <div style="width: 100%">
                          <b-form-select
                              class="form-select"
                              id="formrow-inputState"
                              :options="['Categoria', 'Produto']"
                              v-model="tipoVinculo"
                              type="text"
                          ></b-form-select>
                        </div>
                      </div>
                    </div>

                    <!-- CASO TENHA ESCOLHIDO CATEGORIA COMO VINCULO -->
                    <div class="mb-3" v-if="tipoVinculo === 'Categoria'">
                      <label class="control-label">Escolha uma categoria</label>
                      <div class="d-flex justify-content-between align-content-center gap-1">
                        <div style="width: 100%">
                          <b-form-select
                              class="form-select"
                              id="formrow-inputState"
                              :options="category.map((c) => {return c['nome']})"
                              v-model="categoriaParaVinculo"
                              type="text"
                          ></b-form-select>
                        </div>
                      </div>
                    </div>
                    <!-- CASO TENHA ESCOLHIDO PRODUTO COMO VINCULO -->
                    <div class="mb-3" v-if="tipoVinculo === 'Produto'">
                      <label class="control-label">Procure pelo os produtos</label>
                      <form class="app-search">
                        <div class="position-relative">
                          <input
                              type="text"
                              class="form-control"
                              v-model="searchProductString"
                              :placeholder="$t('navbar.search.text')"
                          />
                          <span class="bx bx-search-alt"></span>
                          <div class="search-results" v-if="productsResults.length > 0">
                            <div class="search-result-item" v-for="product in productsResults" :key="product['id']">
                              <p @click="addProduct(product)"> {{ product['nome'] }}</p></div>
                          </div>
                        </div>
                      </form>
                      <div>
                        <ul v-if="productsSelecteded.length > 0">
                          <li
                              v-for="product in productsSelecteded"
                              :key="product['id']"
                              @click="removeProduct(product)"
                              style="cursor:pointer;"
                          >
                            {{ product['nome'] }}
                          </li>
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="row justify-content-center">
                  <p class="text-center py-4"><strong>Atenção! A proporção ideal para as imagens é: ?</strong></p>
                  <!-- IMAGENS DO BANNER-->
                  <div class="col col-md-3" v-for="file in dropzoneFile" :key="file.name">
                    <div class="body-card">
                      <img
                          style="width: 100%; height: 100%"
                          class="rounded me-2"
                          :alt="file.name"
                          width=""
                          :src="getUrl(file)"
                          data-holder-rendered="true"
                      />
                    </div>
                    <div class="remover-img text-center" @click.prevent="removeFile(file)">
                      <a href="">Remover imagem</a>
                    </div>
                  </div>
                </div>

                <!-- Botoẽs -->
                <div class="mt-2 text-center">
                  <button type="submit" class="btn btn-primary me-1">
                    Salvar
                  </button>
                  <button class="btn btn-secondary" @click.prevent="this.$router.push({name: 'Discounts'})">
                    Cancelar
                  </button>
                </div>
              </form>


            </div>
          </div>
        </div>

      </div>

    </form>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
.search-results {
  position: absolute;
  margin-top: 5px;
  width: 100%;
  background-color: #fff;
  overflow: auto;
  height: 200px;
  z-index: 1;
}

.search-result-item {
  font-size: 14px;
  cursor: pointer;
}

.search-result-item p {
  margin: 0;
  padding: 5px 20px;
}

.search-result-item:hover {
  background-color: #3d9bef;
  color: #fff;
}
</style>

