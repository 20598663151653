import { api } from '@/service';

export const state = {
  category: [],
  createErrorCategory: {},
};

export const mutations = {
  UPDATE_CATEGORY: (state, value) => (state.category = value),
  UPDATE_CREATE_ERROR_CATEGORY: (state, value) =>
    (state.createErrorCategory = value),
};

export const actions = {
  /**
   * Busca todas as categorias
   * @param commit
   * @returns {Promise<AxiosResponse<any>>}
   */
  getCategory({ commit }) {
    return api.get('/category').then((response) => {
      if (response.status === 200) commit('UPDATE_CATEGORY', response.data);
    });
  },
  /**
   * Cadastra uma nova categoria
   * @param dispatch
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  addCategory({ commit, dispatch }, payload) {
    return api.post('/category/add', payload).then((response) => {
      if (response.status === 201) dispatch('getCategory');
      else {
        commit('UPDATE_CREATE_ERROR_CATEGORY', {
          message: response.data['message'],
        });
      }
    });
  },
  /**
   * Atualiza o status de uma categoria
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  changeStatusCategory({ dispatch }, payload) {
    return api
      .put(`/category/update/${payload['id']}`, payload['data'])
      .then((response) => {
        if (response.status === 200) dispatch('getCategory');
      });
  },
  /**
   * Deleta uma categoria pela o id
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  deleteCategoryById({ dispatch }, payload) {
    return api.delete(`/category/delete/${payload['id']}`).then((response) => {
      if (response.status === 200) dispatch('getCategory');
    });
  },
};
