import { api } from '@/service';

export const state = {
  products: [],
  newProduct: null,
  findProduct: {},
};

export const mutations = {
  UPDATE_PRODUCTS: (state, value) => (state.products = value),
  UPDATE_FIND_PRODUCT: (state, value) => (state.findProduct = value),
  UPDATE_NEW_PRODUCT: (state, value) => (state.newProduct = value),
};

export const getters = {};

export const actions = {
  /**
   * Busca os produtos
   * @param commit
   * @returns {Promise<AxiosResponse<any>>}
   */
  getProducts({ commit }) {
    return api.get('/product/filter').then((response) => {
      if (response.status === 200)
        commit('UPDATE_PRODUCTS', response.data['products']);
    });
  },
  /**
   * Cadastra um produto
   * @param commit
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  addProduct({ commit }, payload) {
    return api.post('/product/add', payload).then((response) => {
      if (response.status === 201) commit('UPDATE_NEW_PRODUCT', response.data);
      else commit('UPDATE_NEW_PRODUCT', null);
    });
  },
  /**
   * Vincula uma imagem da galeria ao produto
   * @param state
   * @param commit
   * @param payload // {id: 1, ids:[1,2,3,]}
   * @returns {Promise<AxiosResponse<any>>}
   */
  addImageToProduct({ state, commit }, payload) {
    let tmp = payload.map((id) => {
      return {
        galeria_imagem_id: id,
        produto_id: state.newProduct['id'],
        thumbnail: false,
      };
    });

    tmp[tmp.length - 1] = {
      galeria_imagem_id: tmp[tmp.length - 1]['galeria_imagem_id'],
      produto_id: state.newProduct['product']['id'],
      thumbnail: true,
    };

    tmp.forEach(async (t) => {
      await api.post('/product/image/add', t);
    });
    commit('UPDATE_NEW_PRODUCT', null);
  },
  /**
   * Vincula uma imagem da galeria ao produto
   * @param state
   * @param payload // {id: 1, ids:[1,2,3,]}
   * @returns {Promise<AxiosResponse<any>>}
   */
  // eslint-disable-next-line no-empty-pattern
  addImageToProductUpdate({}, payload) {
    payload['ids'].forEach(async (id) => {
      const data = {
        galeria_imagem_id: id,
        produto_id: payload['id'],
        thumbnail: true,
      };
      await api.post('/product/image/add', data);
    });
    // commit('UPDATE_NEW_PRODUCT', null)
  },
  /**
   * Remove uma imagem do produto
   * @param idProdutoGaleria
   */
  // eslint-disable-next-line no-empty-pattern
  removeImageToProduct({}, idProdutoGaleria) {
    console.log(idProdutoGaleria);
    return api.delete(`/product/image/remove/${idProdutoGaleria}`);
  },
  /**
   * Atualiza o status de um produto pelo o id
   * @param dispatch
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  changeStatusProduct({ dispatch }, payload) {
    return api
      .put(`/product/update/${payload['id']}`, payload)
      .then((response) => {
        if (response.status === 200) dispatch('getProducts');
      });
  },
  /**
   * Deleta um produto pelo o id
   * @param dispatch
   * @param idProduct
   */
  deleteProduct({ dispatch }, idProduct) {
    return api.delete(`/product/delete/${idProduct}`).then((response) => {
      if (response.status === 200) dispatch('getProducts');
    });
  },
  /**
   * Busca as informações de um produto pelo o id
   * @param commit
   * @param idProduct
   * @returns {Promise<AxiosResponse<any>>}
   */
  findById({ commit }, idProduct) {
    return api.get(`/product/find/${idProduct}`).then((response) => {
      if (response.status === 200) commit('UPDATE_FIND_PRODUCT', response.data);
    });
  },
  /**
   * Atualiza o produto pelo o id
   * @param dispatch
   * @param idProduct
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  updateProductById({ dispatch }, payload) {
    return api
      .put(`/product/update/${payload['id']}`, payload['product'])
      .then((response) => {
        if (response.status === 200) dispatch('findById', payload['id']);
      });
  },

  // eslint-disable-next-line no-empty-pattern
  changeThumbnailId({}, payload) {
    return api.put(
      `/product/change/thumbnail/${payload['produto_imagem']}`,
      payload['data'],
    );
  },
};
