<script>
import Layout from '../../layouts/main';
import appConfig from '@/app.config';
import PageHeader from '@/components/page-header';
import {mapState} from "vuex";
import {informationMethods} from "@/state/helpers";

/**
 * Painel de controle Component
 */
export default {
  page: {
    title: 'Painel de controle',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: 'Painel de Controle',
      items: [
        {
          text: 'Painel de controle',
          href: '/',
        },
        {
          text: 'Home',
          active: true,
        },
      ],
    };
  },
  computed: {
    ...mapState('information', ['informations'])
  },
  methods: {
    ...informationMethods,
  },
  async created() {
    await this.getInformations()
  },
  mounted() {
    setTimeout(() => {
      this.showModal = true;
    }, 1500);
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>

    <!-- Conteudo da Home -->
    <div class="container">
      <!--      <div class="row">-->
      <!--        <div class="col">-->
      <!--          <div class="row">-->
      <!--            <div class="col col-sm-4 col-lg-4">-->
      <!--              <div class="card mini-stats-wid">-->
      <!--                <div class="card-body">-->
      <!--                  <div class="d-flex flex-wrap">-->
      <!--                    <div class="me-3">-->
      <!--                      <p class="text-muted mb-2">Total de Produtos</p>-->
      <!--                      <h5 class="mb-0">1.400</h5>-->
      <!--                    </div>-->

      <!--                    <div class="avatar-sm ms-auto">-->
      <!--                      <div-->
      <!--                          class="avatar-title bg-light rounded-circle text-primary font-size-20"-->
      <!--                      >-->
      <!--                        <i class="bx bx-package"></i>-->
      <!--                      </div>-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="col col-sm-4 col-lg-4">-->
      <!--              <div class="card blog-stats-wid">-->
      <!--                <div class="card-body">-->
      <!--                  <div class="d-flex flex-wrap">-->
      <!--                    <div class="me-3">-->
      <!--                      <p class="text-muted mb-2">Total de clientes</p>-->
      <!--                      <h5 class="mb-0">500</h5>-->
      <!--                    </div>-->

      <!--                    <div class="avatar-sm ms-auto">-->
      <!--                      <div-->
      <!--                          class="avatar-title bg-light rounded-circle text-primary font-size-20"-->
      <!--                      >-->
      <!--                        <i class="bx bx-user"></i>-->
      <!--                      </div>-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="col col-sm-4 col-lg-4">-->
      <!--              <div class="card blog-stats-wid">-->
      <!--                <div class="card-body">-->
      <!--                  <div class="d-flex flex-wrap">-->
      <!--                    <div class="me-3">-->
      <!--                      <p class="text-muted mb-2">Total de vendas</p>-->
      <!--                      <h5 class="mb-0">4.235</h5>-->
      <!--                    </div>-->

      <!--                    <div class="avatar-sm ms-auto">-->
      <!--                      <div-->
      <!--                          class="avatar-title bg-light rounded-circle text-primary font-size-20"-->
      <!--                      >-->
      <!--                        <i class="bx bxs-wallet"></i>-->
      <!--                      </div>-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="row">
        <div class="col">

          <div class="row">
            <div class="col col-sm-6 col-lg-6">
              <div class="card mini-stats-wid">
                <div class="card-body">
                  <div class="d-flex flex-wrap">
                    <div class="me-3">
                      <p class="text-muted mb-2">Total de produtos</p>
                      <h5 class="mb-0">{{ informations['products'] }}</h5>
                    </div>

                    <div class="avatar-sm ms-auto">
                      <div
                          class="avatar-title bg-light rounded-circle text-primary font-size-20"
                      >
                        <i class="bx bx-money"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--            <div class="col col-sm-6 col-lg-6">-->
            <!--              <div class="card blog-stats-wid">-->
            <!--                <div class="card-body">-->
            <!--                  <div class="d-flex flex-wrap">-->
            <!--                    <div class="me-3">-->
            <!--                      <p class="text-muted mb-2">Produto mais vendido</p>-->
            <!--                      <h5 class="mb-0">Nome do produto</h5>-->
            <!--                    </div>-->

            <!--                    <div class="avatar-sm ms-auto">-->
            <!--                      <div-->
            <!--                          class="avatar-title bg-light rounded-circle text-primary font-size-20"-->
            <!--                      >-->
            <!--                        <i class="bx bxs-purchase-tag-alt"></i>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>

        </div>
      </div>
    </div>
    <!-- end row -->

    <!--   
  um modal simples 
  
  <b-modal v-model="showModal" hide-footer centered header-class="border-0">
      <div class="text-center mb-4">
        <div class="avatar-md mx-auto mb-4">
          <div class="avatar-title bg-light rounded-circle text-primary h1">
            <i class="mdi mdi-email-open"></i>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-xl-10">
            <h4 class="text-primary">Subscribe !</h4>
            <p class="text-muted font-size-14 mb-4">
              Subscribe our newletter and get notification to stay update.
            </p>

            <div class="input-group bg-light rounded">
              <input
                type="email"
                class="form-control bg-transparent border-0"
                placeholder="Enter Email address"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
              />

              <button class="btn btn-primary" type="button" id="button-addon2">
                <i class="bx bxs-paper-plane"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal> -->
  </Layout>
</template>
<style scoped>
.img-card {
  padding: 20px;
}

.center {
  justify-content: center;
  display: flex;
}

.card {
  border-radius: 10px;
}
</style>
